import React, { useContext, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// import { AiOutlineMenu } from "react-icons/ai";

import userContext from "../../context/user/userContext";
import axios from "axios";
import Spinner from "../../components/Spinner/Spinner";
const Dashboard = () => {
  const {
    state,
    updateLoader,
    removeToken,
    addProductsItems,
    addCategoryItems,
    sideBarOpen,
  } = useContext(userContext);
  const navigate = useNavigate();
  // console.log(state);
  const {
    products,
    category,
    jsonToken,
    partnerName,
    sideBar,
    services,
    masterServices,
    teasers,
  } = state;
  // console.log("OJSON TOKEN ", state);

  useEffect(() => {
    if (!sideBar) sideBarOpen();
  }, []);
  useLayoutEffect(() => {
    const fetchProducts = async (jsonToken) => {
      // updateLoader(true);
      // console.log("partnerName ", partnerName);
      // console.log(" GETTING PRODUCT ==>>>");
      const response = await axios.get(
        `/product/fetch-products/?partner=${state.userName}`,
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );

      // console.log("response ", response.data.message);

      return await response.data.message;
    };
    if (state.jsonToken) {
      // console.log("STATETT ", state);
      fetchProducts(state.jsonToken)
        .then((item) => {
          // console.log("response ", item);
          // console.log("jo");
          addProductsItems(item);
          updateLoader(false);
        })
        .catch((err) => {
          console.log(err);
          navigate("/login");
          updateLoader(false);
          removeToken();
        });
    }
  }, [jsonToken]);

  useLayoutEffect(() => {
    try {
      if (jsonToken) {
        const fetchItem = async (jsonToken) => {
          const response = await axios.get(
            `/category/fetch-category/?partner=${partnerName}`,
            {
              headers: { Authorization: `Bearer ${jsonToken}` },
            }
          );

          return await response.data.data;
        };

        updateLoader(true);
        fetchItem(jsonToken)
          .then((item) => {
            updateLoader(false);
            addCategoryItems(item);
          })
          .catch((err) => {
            navigate("/login");
            updateLoader(false);
            removeToken();
          });
      }
    } catch (err) {
      console.log(err);
      removeToken();
      navigate("/login");
    }
  }, [jsonToken, partnerName]);

  // console.log("PARTNER NAME ", partnerName);
  return (
    <StyledDashboard sideBar={sideBar}>
      <div className="header">
        {/* <h4 onClick={() => navigate("/category")}>Add Categories</h4>
        <h4 onClick={() => navigate("/products")}>Add Products</h4>
        <h4
          onClick={() => {
            removeToken();
            navigate("/login");
          }}
        >
          Logout
        </h4> */}
      </div>
      <div className="container">
        {products.length >= 0 &&
        masterServices.masterServicesName !== undefined ? (
          <div className="content-section">
            <h5>WELCOME {partnerName !== "" && partnerName.toUpperCase()}</h5>
            <div className="content-items">
              <div
                className="services "
                onClick={() => navigate("/master-services")}
              >
                <h3>Total Bouquets </h3>
                <h3>{masterServices.masterServicesName.length}</h3>
              </div>
              <div
                className="service-items"
                style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="products"
                  onClick={() => navigate("/sub-categories")}
                >
                  <h3>Total Series </h3>
                  <h3>{category.length}</h3>
                </div>
                <div className="products" onClick={() => navigate("/products")}>
                  <h3>Total Videos </h3>
                  <h3>{products.length}</h3>
                </div>
                <div
                  className="categories"
                  onClick={() => navigate("/categories")}
                >
                  <h3>Total Channels</h3>
                  <h3>{services.length}</h3>
                </div>
                <div
                  className="categories"
                  onClick={() => navigate("/teasers")}
                >
                  <h3>Total Teasers</h3>
                  <h3>{teasers.length}</h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;

const StyledDashboard = styled.div`
  background-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8aa7ec,
    #79b3f4,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  );

  min-height: 100%;
  /* min-width: 100vw; */

  .header {
    height: 9vh;
    /* min-width: 100vw; */
    background-color: var(--ndoto-header-bg);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }
  }

  h3 {
    font-weight: 800;
    font-size: 20px;
  }
  h4 {
    color: black;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  .container {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h5 {
      font-size: 30px;
      text-align: center;
      text-shadow: 1px 3px 0 #969696, 1px 13px 5px #aba8a8,
        2px 2px 2px rgba(66, 154, 206, 0);
    }
  }
  .content-items {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .products {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: cyan;
    /* max-height: 200px; */
    padding: 20px;
    max-width: 200px;
    margin: 20px;
    /* border: 1px solid black; */
    text-align: center;
    box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    cursor: pointer;

    :hover {
      background-color: black;
      transition: 1s all ease-in-out;
      h3 {
        color: pink;
      }
    }
  }
  .categories {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: cyan;
    /* height: 200px; */
    max-width: 200px;
    padding: 20px;
    margin: 20px;
    /* border: 1px solid black; */
    text-align: center;
    box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    :hover {
      background-color: black;
      transition: 1s all ease-in-out;
      h3 {
        color: pink;
      }
    }
  }

  .services {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: cyan;
    /* height: 100px; */
    max-width: 80%;
    padding: 20px;
    border-radius: 10px;
    /* border: 1px solid black; */
    text-align: center;
    box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -10px -7px 19px 0px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    :hover {
      background-color: black;
      transition: 1s all ease-in-out;
      h3 {
        color: pink;
      }
    }
  }

  @media (max-width: 640px) {
    .container {
      /* padding: 50px; */
      width: 100%;
      height: 100%;
      padding: 0;

      /* overflow: hidden; */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .content-section {
      height: 100%;
      width: 100%;
      background-color: #ccc;
      margin-left: 80px;

      /* margin: auto; */
    }
    .content-items {
      /* background-color: yellow; */
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
    }
    .services {
      height: 150px;
      border-radius: 5px;
    }
    .products {
      height: 150px;
    }
    .categories {
      /* margin: 50px; */
      height: 150px;
    }

    h5 {
      margin-bottom: 50px;
    }
    .products {
      margin: 60px;
    }
  }

  @media (max-width: 1024px) {
    .service-items {
      flex-direction: column;
    }
  }
`;
