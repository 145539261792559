import React from "react";

import { Table } from "antd";
import { useState } from "react";
import _ from "lodash";
import { arrayMoveImmutable } from "array-move";
import { MenuOutlined } from "@ant-design/icons";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";
const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: "grab",
      color: "#999",
    }}
  />
));
const columns = [
  {
    title: "Sort",
    dataIndex: "sort",
    width: 30,
    className: "drag-visible",
    render: () => <DragHandle />,
  },
  {
    title: "Name",
    dataIndex: "name",
    className: "drag-visible",
  },
  {
    title: "Age",
    dataIndex: "age",
  },
  {
    title: "Address",
    dataIndex: "address",
  },
];
const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    index: 0,
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    index: 1,
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    index: 2,
  },
  {
    key: "4",
    name: "Nitin",
    age: 32,
    address: "New York No. 1 Lake Park",
    index: 3,
  },
  {
    key: "5",
    name: "Rakesh",
    age: 42,
    address: "London No. 1 Lake Park",
    index: 4,
  },
  {
    key: "6",
    name: "Ashu",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    index: 5,
  },
];
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const DragTest = () => {
  const [dataSource, setDataSource] = useState(data);
  const [changedArray, setChangedArray] = useState(data);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("oldIndex ", oldIndex);
    console.log("newIndex", newIndex);

    console.log("Source", data[oldIndex]);
    console.log("FInal", data[newIndex]);

    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      setDataSource(newData);
      setChangedArray(_.pullAllWith(data, newData, _.isEqual));
    }
  };

  console.log("changedArray", changedArray);

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <DragDivStyled>
      <Table
        // scroll={{ x: 1500, y: 300 }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </DragDivStyled>
  );
};

export default DragTest;

const DragDivStyled = styled.div`
  height: 100%;
`;
