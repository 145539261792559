import React, { useContext, useLayoutEffect, useState, useEffect } from "react";
import { Tooltip, Input, Form, Upload, Select, Button, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import userContext from "../../context/user/userContext";
import styled from "styled-components";
import { IoIosAddCircle } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";
// import { FcHome } from "react-icons/fc";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import Modal from "../../components/Modal/Modal";
import ProductTable from "../../components/ProductTable/ProductTable";
import OverLaySpinner from "../../components/OverlaySpinner";

const ShowCatWiseVideos = () => {
  let country = "";
  let menu = "";
  const {
    state,
    updateLoader,
    deleteProduct,
    updateServicesItems,
    sideBarOpen,
    addProductsItems,
    addOperator,
    removeToken,
    editProductImage,
  } = useContext(userContext);
  const { Option } = Select;
  const { products, sideBar } = state;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [successEdit, setSuccessEdit] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [duration, setDuration] = useState(0);
  const [uploadType, setUploadType] = useState("");
  const [successAdded, setSuccessAdded] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const params = useParams();
  console.log("params ", params);
  console.log("products ", products);
  const navigate = useNavigate();

  function handleChangeUpload(value) {
    console.log(`selected ${value}`);
    setUploadType(value);
  }
  useLayoutEffect(() => {
    console.log("prams ", params.catId);
    const selectedItems = products.filter(
      (item) =>
        item.category_id == params.catId &&
        item.sub_category_id == params.subCatid
    );

    setSelectedVideos(selectedItems);
  }, [products]);
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const onChange = ({ fileList: newFileList }) => {
    console.log(fileList);
    setFileList(newFileList);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    // console.log(" ADD opeartor");
    const fetchOperator = async (jsonToken) => {
      // updateLoader(true);
      const response = await axios.get("/operator/fetch-operator", {
        headers: { Authorization: `Bearer ${state.jsonToken}` },
      });

      return await response.data.data;
    };

    if (state.jsonToken) {
      fetchOperator(state.jsonToken)
        .then((item) => {
          // console.log("jo");
          addOperator(item);
          updateLoader(false);
        })
        .catch((err) => {
          console.log(err);
          // navigate("/login");
          updateLoader(false);
          removeToken();
        });
    }
  }, [state.jsonToken]);

  const formSubmitHandler = () => {
    console.log("Submit");
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
    setSelectedOperator(value);
  }

  const handleSubmitUrl = async (values) => {
    const {
      title,
      operator,
      country,
      category,
      description,
      image,
      video,
      duration,
    } = values;

    try {
      updateLoader(true);
      const catId = state.category.find((item) => {
        console.log(item);
        console.log(category);
        setIsLoading(true);
        if (item.name === category) return item.id;
      });
      console.log("CAT ID ", catId);
      // console.log("values.image.originFileObj ", values.video[0]);

      const formData = new FormData();
      formData.append("partnerName", state.partnerName);
      formData.append("image", image);
      formData.append("video", video);
      formData.append("title", title);
      formData.append("operator", operator);
      formData.append("country", country);
      formData.append("description", description);
      formData.append("catId", catId.id);
      formData.append("duration", duration);
      formData.append("status", 0);

      // formData.append("age", 20);

      // console.log("formData ", formData);

      console.log(values);

      const response = await axios.post(
        "/addProducturl/addItem",
        {
          image,
          partnerName: state.partnerName,
          video,
          title,
          operator,
          country,
          description,
          catId: catId.id,
          duration,
          status: 0,
        },

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );

      const responseFetch = await axios.get(
        `/product/fetch-products/?partner=${state.partnerName}`,

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      console.log("fetch-products ", responseFetch.data.message);
      updateLoader(false);
      addProductsItems(responseFetch.data.message);
      setIsLoading(false);
      setSuccessAdded(true);

      console.log("RESPONSE ", response);
    } catch (err) {
      updateLoader(false);
      setIsLoading(false);
      navigate("/login");
      alert("Some thing went wrong pleaase try again later");
    }
  };
  const handleSubmit = async (values) => {
    const {
      title,
      operator,
      country,
      category,
      description,
      image,
      video,
      // duration,
    } = values;

    try {
      const catId = state.category.find((item) => {
        console.log(item);
        console.log(category);
        setIsLoading(true);
        if (item.name === category) return item.id;
      });
      updateLoader(true);
      console.log("CAT ID ", catId);
      console.log("values.image.originFileObj ", values.video[0]);

      const formData = new FormData();
      formData.append("partnerName", state.partnerName);
      formData.append("image", values.image.file.originFileObj);
      formData.append("video", values.video[0].originFileObj);
      formData.append("title", title);
      formData.append("operator", operator);
      formData.append("country", country);
      formData.append("description", description);
      formData.append("catId", catId.id);
      // formData.append("duration", duration);
      formData.append("status", 0);

      // formData.append("age", 20);

      // console.log("formData ", formData);

      console.log(values);

      const response = await axios.post(
        "/product/add-product",
        formData,
        // {
        //   title,
        //   operator,
        //   country,
        //   category,
        //   description,
        // },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      updateLoader(false);

      const responseFetch = await axios.get(
        `/product/fetch-products/?partner=${state.partnerName}`,

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      console.log("fetch-products ", responseFetch.data.message);
      addProductsItems(responseFetch.data.message);
      setIsLoading(false);
      setSuccessAdded(true);

      console.log("RESPONSE ", response);
    } catch (err) {
      setIsLoading(false);
      updateLoader(false);
      alert("Some thing went wrong pleaase try again later");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setVisible(false);
    setIsLoading(false);
    setError("");
    setSuccessMessage("");
    setConfirm(false);
    setIsEdit("");

    form.resetFields();
  };

  const onDelete = async (id) => {
    try {
      updateLoader(true);
      // console.log(" DELETE ", id);

      const response = await axios.delete(
        "/product/remove-products",
        {
          data: {
            id,
            partnerName: state.partnerName,
          },
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      deleteProduct(id);
      console.log("response ", response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
      alert("SOME THING Went wrong try again later");
    }
  };

  const toggleItem = async (status, id) => {
    // console.log(id, status);
    try {
      updateLoader(true);
      // console.log(" DELETE ", id);

      const response = await axios.post(
        "/product/toggleProduct",
        {
          id,
          status,
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      console.log("response ", response.data.message);
      updateServicesItems(response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
    }
  };
  console.log("selectedItems ", selectedVideos);
  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  if (state.operator.length > 0 && state.category.length > 0) {
    // var menu = <Option value="">Jack</Option>;
    menu = state.operator.map((item, index) => (
      <Option key={index} value={item.name}>
        {item.name}
      </Option>
    ));

    country = state.operator.map((item, index) => {
      // console.log(" ITEM ", item, selectedOperator);
      if (item.name === selectedOperator)
        return (
          <Option key={index} value={item.name}>
            {item.country}
          </Option>
        );
    });

    var catItem = state.category.map((item, index) => (
      <Option key={index} value={item.name}>
        {item.name}
      </Option>
    ));
  }

  const editImage = async (id, image) => {
    try {
      updateLoader(true);
      console.log(id, image);

      const formData = new FormData();
      formData.append("id", id);
      formData.append("partnerName", state.partnerName);
      formData.append("image", image);

      const response = await axios.post(
        "/product/edit-product-image",
        formData
      );
      updateLoader(false);
      console.log(response);
      editProductImage(id, response.data.message);
    } catch (e) {
      updateLoader(false);
      console.log(e);
    }
  };

  return (
    <ShowCatWiseVideosStyled sideBar={sideBar}>
      <div className="add-product">
        {/* <div className="menu-icon">
          <AiOutlineMenu
            onClick={() => {
              sideBarOpen();
            }}
          />
        </div> */}
        {/* <FcHome
          size={20}
          className="home-icon"
          onClick={() => navigate("/dashboard")}
        /> */}
        <span onClick={() => setIsModalVisible(true)}>
          <h5>Add Videos</h5>
          <Tooltip title="Add Product">
            {<IoIosAddCircle size={"20px"} className="add-icon" />}
          </Tooltip>
        </span>
        <Modal
          heading="Add Product"
          visible={isModalVisible}
          onOk={form.submit}
          onCancel={handleCancel}
          formSubmitHandler={formSubmitHandler}
          isLoading={isLoading}
          error={error}
          successMessage={successMessage}
          width={1000}
        >
          <Form
            form={form}
            onFinish={uploadType === "upload" ? handleSubmit : handleSubmitUrl}
            autoComplete="off"
            // {...formItemLayout}
          >
            <Form.Item
              name="title"
              label="Enter Title"
              rules={[{ required: true, message: "This field is required." }]}
              style={{ width: 500 }}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="operator"
                  label="Enter Operator"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    placeholder="select operator"
                    style={{ width: 200 }}
                    onChange={handleChange}
                  >
                    {menu}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label="Choose Country"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    placeholder="select Country"
                    style={{ width: 200 }}
                    // onChange={handleChange}
                  >
                    {country}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="description"
              label="Enter Description"
              rules={[{ required: true, message: "This field is required." }]}
              style={{ width: 800 }}
            >
              <Input.TextArea
                placeholder="Enter Description"
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Enter Category"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    placeholder="select Category"
                    style={{ width: 200 }}
                    // onChange={handleChange}
                  >
                    {catItem}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="select"
                  label="Please select a Video Option"
                  hasFeedback
                  rules={[
                    { required: true, message: "Please select your country!" },
                  ]}
                >
                  <Select
                    placeholder="Please select a Video Option"
                    style={{ width: 150 }}
                    onChange={handleChangeUpload}
                  >
                    <Option value="upload">File Upload</Option>
                    <Option value="url"> Url</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {uploadType == "upload" ? (
              <Form.Item
                name="video"
                label="Upload Video"
                valuePropName="fileList"
                getValueFromEvent={normFile}

                // extra="longgggggggggggggggggggggggggggggggggg"
              >
                <Upload
                  accept="video/*"
                  name={"video"}
                  listType="picture"
                  customRequest={dummyRequest}
                  // onChange={chosenVideoHandler}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            ) : uploadType == "url" ? (
              <Form.Item
                name="video"
                label="Enter Video Url"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Enter url" type="text" />
              </Form.Item>
            ) : null}

            {uploadType === "upload" ? (
              <Form.Item name="image" label="Upload Image">
                <Upload
                  name={"image"}
                  accept=".png, .jpg, .jpeg"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  // maxCount={1}
                  customRequest={dummyRequest}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
                {/* </ImgCrop> */}
              </Form.Item>
            ) : uploadType == "url" ? (
              <Form.Item
                name="image"
                label="Enter Image Url"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Enter url" type="text" />
              </Form.Item>
            ) : null}

            {/* <Form.Item
              name="duration"
              label="Video Duration"
              rules={[{ required: true, message: "This field is required." }]}
              style={{ width: 400 }}
            >
              <Input
                placeholder="Enter correct Duration Of video"
                type="text"
              />
            </Form.Item> */}
          </Form>
          {state.loader ? <OverLaySpinner size={500} modal={true} /> : null}
        </Modal>
      </div>
      {successAdded ? (
        <Modal
          heading="Successfully Added Product"
          visible={true}
          onOk={() => {
            setSuccessAdded(false);
            setIsModalVisible(false);
            setVisible(false);
            form.resetFields();
            setFileList([]);
          }}
          onCancel={handleCancel}
          // formSubmitHandler={formSubmitHandler}
          isLoading={isLoading}
          error={error}
          showSubmit={false}
          // successMessage={successMessage}
        ></Modal>
      ) : null}
      <div className="main">
        <ProductTable
          items={selectedVideos}
          // deleteitem={onDelete}
          deleteitem={onDelete}
          toggleItem={toggleItem}
          editImage={editImage}
          editItem={async (data) => {
            try {
              updateLoader(true);
              console.log("hi", data);
              const response = await axios.patch(
                "/product/edit-product-name",
                data
              );
              // console.log(response);
              updateLoader(false);
            } catch (err) {
              updateLoader(false);
              console.log(err);
            }
          }}
        />

        {state.loader ? <OverLaySpinner size={100} /> : null}
      </div>
    </ShowCatWiseVideosStyled>
  );
};

export default ShowCatWiseVideos;

const ShowCatWiseVideosStyled = styled.div`
  .main {
    min-height: 90vh;
  }
  .add-product {
    min-height: 50px;
    min-width: 100%;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        color: cyan;
      }
      h5 {
        color: white;
        padding: 0 10px;
        :hover {
          color: cyan;
        }
      }
    }
  }
`;
