import { Form, Input, Button, Checkbox, Spin } from "antd";

const LoginComponent = ({ formHandler, loader, error }) => {
  const onFinish = (values) => {
    // console.log("Success:", values);
    formHandler(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label={<label style={{ color: "white" }}>UserName</label>}
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={<label style={{ color: "white" }}>Password</label>}
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      {error && (
        <h5
          style={{
            textAlign: "center",
            color: "red",
            fontSize: "12px",
            // padding: "10px",
            margin: 0,
            marginLeft: "20px",
            marginBottom: "20px",
          }}
        >
          {error}
        </h5>
      )}

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>
          {<label style={{ color: "white" }}>Remember me</label>}
        </Checkbox>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" style={{ minWidth: "100px" }}>
          {loader ? <Spin /> : "Sign-in"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginComponent;
