import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
// import GlobalStyles from "./components/Global";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import "antd/dist/antd.css";

import userContext from "./context/user/userContext";
import Footer from "./components/Footer/Footer";
import SideBar from "./components/Sidebar/SideBar";

function App() {
  const {
    state,
    updateToken,
    addCategoryItems,
    updateLoader,
    removeToken,
    // addOperator,
    addProductsItems,
    addTeaserItems,
    addServicesItems,
    addMasterServices,
  } = useContext(userContext);

  const { jsonToken, partnerName } = state;
  const [broswerLocation, setBrowserLocation] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(" Current loacation ", location);

  const [logging, setLogging] = useState(false);
  // console.log("state ", state);

  const [cookies, setCookie] = useCookies(["user"]);
  // console.log("cookies ", cookies.user);

  useEffect(() => {
    const token = JSON.parse(window.localStorage.getItem("token"));

    // console.log("token ", token);

    updateToken("", token, "");
    // console.log("jsonToken ", token);
    // console.log("locartion browser ", broswerLocation);
    setBrowserLocation(location.pathname);
    if (token == null || token == "") return navigate("/login");
    // else navigate(location.pathname);
    else {
      const fetchUserAccess = async () => {
        const fetchUserAccess = await axios.get(`/user/fetch-user-access`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log("Authorization ", fetchUserAccess);
        return fetchUserAccess.data;
      };

      fetchUserAccess()
        .then((data) =>
          updateToken(data.user, data.jsonToken, data.partnername)
        )
        .catch((err) => navigate("/login"));

      if (location.pathname == "/login") {
        return navigate("/dashboard");
      } else {
        return navigate(location.pathname);
      }
    }
  }, []);

  useEffect(() => {
    const fetchTeasers = async (jsonToken) => {
      // updateLoader(true);
      // console.log("partnerName ", partnerName);
      // console.log(" GETTING PRODUCT ==>>>");
      const response = await axios.get(
        `/teasers/fetch-teasers/?partner=${partnerName}`,
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );

      // console.log("response ", response.data.message);

      return await response.data.message;
    };
    if (state.jsonToken) {
      // console.log("STATETT ", state);
      fetchTeasers(state.jsonToken)
        .then((item) => {
          // console.log("jo");
          addTeaserItems(item);
          updateLoader(false);
        })
        .catch((err) => {
          console.log(err);
          // navigate("/login");
          updateLoader(false);
          removeToken();
        });
    }
  }, [state.partnerName, state.jsonToken]);

  useEffect(() => {
    const fetchServices = async (jsonToken) => {
      // updateLoader(true);
      // console.log("partnerName ", partnerName);
      // console.log(" GETTING PRODUCT ==>>>");
      const response = await axios.get(
        `/services/fetchServices/?partnerName=${partnerName}`,
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );

      console.log("response ", response.data.message);

      return await response.data.message;
    };
    if (state.jsonToken) {
      // console.log("STATETT ", state);
      fetchServices(state.jsonToken)
        .then((item) => {
          // console.log("jo");
          addServicesItems(item);
          updateLoader(false);
        })
        .catch((err) => {
          console.log(err);
          // navigate("/login");
          updateLoader(false);
          removeToken();
        });
    }
  }, [state.partnerName, state.jsonToken, navigate]);

  useEffect(() => {
    const fetchProducts = async (jsonToken) => {
      // updateLoader(true);
      // console.log("partnerName ", partnerName);
      // console.log(" GETTING PRODUCT ==>>>");
      const response = await axios.get(
        `/product/fetch-products/?partner=${partnerName}`,
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );

      // console.log("response ", response.data.message);

      return await response.data.message;
    };
    if (state.jsonToken) {
      // console.log("STATETT ", state);
      fetchProducts(state.jsonToken)
        .then((item) => {
          // console.log("jo");
          addProductsItems(item);
          updateLoader(false);
        })
        .catch((err) => {
          console.log(err);
          // navigate("/login");
          updateLoader(false);
          removeToken();
        });
    }
  }, [state.partnerName, state.jsonToken]);

  useEffect(() => {
    try {
      if (jsonToken) {
        const fetchItem = async (jsonToken) => {
          const response = await axios.get(
            `/category/fetch-category/?partner=${state.partnerName}`,
            {
              headers: { Authorization: `Bearer ${jsonToken}` },
            }
          );

          return await response.data.data;
        };

        updateLoader(true);
        fetchItem(jsonToken)
          .then((item) => {
            updateLoader(false);
            addCategoryItems(item);
          })
          .catch((err) => {
            navigate("/login");
            updateLoader(false);
            removeToken();
          });
      }
    } catch (err) {
      console.log(err);
      removeToken();
      navigate("/login");
    }
  }, [jsonToken, state.partnerName]);

  useEffect(() => {
    try {
      if (jsonToken) {
        const fetchItem = async (jsonToken) => {
          const response = await axios.get(
            `/fetch-master-services/?partner=${state.partnerName}`,
            {
              headers: { Authorization: `Bearer ${jsonToken}` },
            }
          );

          return await {
            data: response.data.data,
            masterServicesName: response.data.masterServiceNames,
          };
        };

        updateLoader(true);
        fetchItem(jsonToken)
          .then((item) => {
            updateLoader(false);
            addMasterServices(item);
          })
          .catch((err) => {
            navigate("/login");
            updateLoader(false);
            removeToken();
          });
      }
    } catch (err) {
      console.log(err);
      removeToken();
      navigate("/login");
    }
  }, [jsonToken, state.partnerName]);
  // console.log("JSON TOKEN ", jsonToken);

  return (
    <>
      {/* {jsonToken ? <SideBar /> : null} */}
      {/* <Footer /> */}
    </>
  );
}

export default App;

// adx
// #adx%1230101HJ

//Dolce
// Dolce$612*&
