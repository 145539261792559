import React from "react";
import { CookiesProvider } from "react-cookie";
import { Routes, Route } from "react-router-dom";
import Category from "../../container/category";
import AddProduct from "../../container/add-product";
import Dashboard from "../../container/Dashboard/Dashboard";
import HomePage from "../../container/HomePage/HomePage";
import Login from "../../container/Login/Login";
import NoPageFound from "../../container/NoPageFound";
import Teasers from "../../container/teasures/Teasers";
import Services from "../../container/ServiceTypes/Services";
import AddServices from "../../container/add-service/AddServices";
import EditServices from "../../container/edit-services.js/EditServices";
import MasterServices from "../../container/masterServices/MasterServices";
import ShowCatWiseVideos from "../../container/ShowCatWiseVideos/ShowCatWiseVideos";
import AddServiceCategories from "../../container/AddServiceCategories/AddServiceCategories";
import Layout from "../Layout/Layout";
import AddVideos from "../../container/Add-Videos/AddVideos";
import DragTest from "../../container/DragTest/DragTest";

const Navigations = () => {
  return (
    <CookiesProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/products" element={<AddProduct />} />
          <Route path="/sub-categories" element={<Category />} />
          <Route path="/teasers" element={<Teasers />} />
          <Route path="/categories" element={<Services />} />
          <Route path="/master-services" element={<MasterServices />} />
          <Route path="/add-service" element={<AddServices />} />
          <Route path="/drag" element={<DragTest />} />

          <Route
            path="/service-categories"
            element={<AddServiceCategories />}
          />
          <Route path="/edit-service/:slug" element={<EditServices />} />
          <Route
            path="/show-cat-wise/:catId/:subCatid"
            element={<ShowCatWiseVideos />}
          />
          <Route path="/add-videos" element={<AddVideos />} />
          <Route path="/*" element={<NoPageFound />} />
        </Routes>
      </Layout>
    </CookiesProvider>
  );
};

export default Navigations;
