import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
// import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { IoIosAddCircle } from "react-icons/io";

import { useNavigate } from "react-router-dom";
import { Tooltip, Form, Select } from "antd";

// import ImgCrop from "antd-img-crop";
import Modal from "../../components/Modal/Modal";
import userContext from "../../context/user/userContext";
import ProductTable from "../../components/ProductTable/ProductTable";
import OverLaySpinner from "../../components/OverlaySpinner";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 5,
  },
};

const AddProduct = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEdit, setIsEdit] = useState([]);

  const [selectedOperator, setSelectedOperator] = useState("");
  const [duration, setDuration] = useState(0);

  const [serviceChanged, setServiceChanged] = useState([]);

  const navigate = useNavigate();

  const [selectedMainService, setSelectedMainService] = useState("");

  let country = "";
  let menu = "";

  const {
    state,
    updateLoader,
    removeToken,
    addOperator,
    addProductsItems,
    deleteProduct,
    updateProductItems,
    sideBarOpen,
    updateServicesItems,
  } = useContext(userContext);

  const { sideBar } = state;

  useEffect(() => {
    if (!sideBar) sideBarOpen();
  }, []);

  if (
    state.operator.length > 0 &&
    state.category.length > 0 &&
    state.masterServices.masterServicesName !== undefined
  ) {
    // var menu = <Option value="">Jack</Option>;
    menu = state.operator.map((item, index) => (
      <Option key={index} value={item.name}>
        {item.name}
      </Option>
    ));

    country = state.operator.map((item, index) => {
      // console.log(" ITEM ", item, selectedOperator);
      if (item.name === selectedOperator)
        return (
          <Option key={index} value={item.name}>
            {item.country}
          </Option>
        );
    });
    var mainServiceItem = state.masterServices.masterServicesName.map(
      (item, index) => (
        <Option key={index} value={item}>
          {item}
        </Option>
      )
    );

    var catIdsFromMainService = state.masterServices.data.filter(
      (item) => item.masterServicename === selectedMainService
    );

    if (catIdsFromMainService.length > 0) {
      var catItem = state.services.filter(
        (item, index) =>
          catIdsFromMainService.some((data) => data.catids === item.category_id)
        //   <Option key={index} value={item.name}>
        //     {item.name}
        //   </Option>
        // )
        // )
      );
      catItem = catItem.map((item, index) => (
        <Option key={index} value={item.category_id}>
          {item.serviceName}
        </Option>
      ));
    }
    // serviceChanged
    var subCatIds = state.category.filter(
      (item) => item.parent_id === serviceChanged
    );

    subCatIds = subCatIds.map((item, index) => (
      <Option key={index} value={item.sub_cat_id}>
        {item.name}
      </Option>
    ));

    // ))
  }

  console.log("catIdsFromMainService ", selectedMainService);
  console.log("catIdsFromMainService ", catIdsFromMainService);
  console.log("catItem ", catItem);
  console.log("subCatIds ", subCatIds);
  const chosenVideoHandler = (e) => {
    console.log(e);
    var file = e.file;
    // console.log(" files", file);

    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      console.log(" files", file);
      window.URL.revokeObjectURL(video.src);
      // console.log("video ", video.duration);
      setDuration(video.duration);

      if (video.duration < 1) {
        console.log("Invalid Video! video is less than 1 second");
        return;
      }
    };

    // video.src = URL.createObjectURL(file);
    console.log("duration ", duration);
  };
  // console.log("state :", state);

  const onDelete = async (id) => {
    try {
      updateLoader(true);
      // console.log(" DELETE ", id);

      const response = await axios.delete(
        "/product/remove-products",
        {
          data: {
            id,
            partnerName: state.partnerName,
          },
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      deleteProduct(id);
      console.log("response ", response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
      alert("SOME THING Went wrong try again later");
    }
  };

  const toggleItem = async (status, id) => {
    // console.log(id, status);
    try {
      updateLoader(true);
      // console.log(" DELETE ", id);

      const response = await axios.post(
        "/product/toggleProduct",
        {
          id,
          status,
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      console.log("response ", response.data.message);
      updateServicesItems(response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
    }
  };

  return (
    <AddProductContainer sideBar={sideBar}>
      <div className="add-product">
        <span onClick={() => navigate("/add-videos")}>
          <h5>Add Videos</h5>
          <Tooltip title="Add Product">
            {<IoIosAddCircle size={"20px"} className="add-icon" />}
          </Tooltip>
        </span>
      </div>
      <div className="main">
        <ProductTable
          items={state.products}
          // deleteitem={onDelete}
          deleteitem={onDelete}
          toggleItem={toggleItem}
          editItem={(data) => setIsEdit(data)}
        />

        {state.loader ? <OverLaySpinner size={100} /> : null}
      </div>
    </AddProductContainer>
  );
};

export default AddProduct;

const AddProductContainer = styled.div`
  /* background-color: yellow; */
  /* background-image: linear-gradient(
    to right bottom,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  ); */
  background-image: linear-gradient(00deg, white, #00000047),
    url(https://www.pdpamatter.com/assets/global/default/assets/media/bg/bg-9.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(4px);
  background-blend-mode: color-burn;
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    background: linear-gradient(
      to right bottom,
      #051937,
      #004d7a,
      #008793,
      #00bf72,
      #a8eb12
    );
    mix-blend-mode: multiply;
    opacity: 0.7;
  }

  .main {
    min-height: 90vh;
  }
  .add-product {
    min-height: 50px;
    min-width: 100%;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
    span {
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        color: cyan;
      }
      h5 {
        color: white;
        padding: 0 10px;
        :hover {
          color: cyan;
        }
      }
    }
  }
`;
