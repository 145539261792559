import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { IoIosAddCircle } from "react-icons/io";
import { Tooltip, Input, Form, Select } from "antd";
import axios from "axios";

// import { FcHome } from "react-icons/fc";
import userContext from "../../context/user/userContext";
import Modal from "../../components/Modal/Modal";
import OverLaySpinner from "../../components/OverlaySpinner";
import CategoryTable from "../../components/CategoryTable/CategoryTable";
import { useNavigate } from "react-router-dom";

const Category = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const {
    state,
    deleteCategory,
    updateCategory,
    editCategory,
    sideBarOpen,
    toggleItem,
  } = useContext(userContext);
  const [confirm, setConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const [successEdit, setSuccessEdit] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState([]);
  // const []
  const [searchboq, setSeatchboq] = useState("");
  console.log("STATE CAT", state.category);

  useEffect(() => {
    console.log("HERE!!!!E", selectedId);
    // if (filteredCategory.length < 1) setFilteredCategory(state.category); commenting intially result 0

    if (
      filteredCategory.length !== state.category.length &&
      selectedId !== ""
    ) {
      setFilteredCategory(
        filteredCategory.filter((item) => item.id !== selectedId)
      );
    }

    if (
      state.category.some((catItem) =>
        filteredCategory.some(
          (item) => item.id === catItem.id && item.name !== catItem.name
        )
      )
    ) {
      // setFilteredCategory([]);
      setFilteredCategory(state.category);
    }

    // console.log(
    //   state.category.some((catItem) =>
    //     filteredCategory.some(
    //       (item) => item.id === catItem.id && item.status !== catItem.status
    //     )
    //   )
    // );

    if (
      state.category.some((catItem) =>
        filteredCategory.some(
          (item) => item.id === catItem.id && item.status !== catItem.status
        )
      )
    ) {
      let newFilter = filteredCategory.map((item) => {
        const selected = state.category.find(
          (data) => data.id === item.id && data.status !== item.status
        );

        if (selected)
          return {
            ...item,
            status: selected.status ? 1 : 0,
          };
        else return item;
      });

      setFilteredCategory(newFilter);
    }
  }, [state.category]);

  console.log("FILEEEEE ", filteredCategory);

  const handleOk = () => {
    setIsModalVisible(false);
    setIsLoading(false);
    setError("");
    setSuccessMessage("");
    setConfirm(false);
    setIsEdit("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setVisible(false);
    setIsLoading(false);
    setError("");
    setSuccessMessage("");
    setConfirm(false);
    setIsEdit("");

    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      setError("");
      setIsLoading(true);
      // console.log(values);

      const response = await axios.post(
        "/category/add-category",
        {
          catname: values.category,
          partnerName: state.partnerName,
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );

      setIsLoading(false);
      updateCategory(response.data.data);
      console.log("response data ", response.data);
      console.log("response ", response.data.message);
      setSuccessMessage(response.data.message);
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data.message);
      setError(err.response.data.message);
      alert(err.response.data.message);
      // navigate("/login");
    }
  };

  const formSubmitHandler = () => {
    console.log("Submit");
  };

  const confirmDelete = async () => {
    try {
      setIsLoading(true);
      console.log("ID ", selectedId);

      const response = await axios.delete(
        `/category/delete/`,
        { data: { id: selectedId } }

        // {
        //   headers: { Authorization: `Bearer ${state.jsonToken}` },
        // }
      );
      console.log("response ", response);
      deleteCategory(selectedId);
      setConfirm(false);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const onDelete = (id) => {
    console.log(id);
    setConfirm(true);
    setSelectedId(id);
  };

  const selectbouquetHandler = (value) => {
    console.log(value);
    setSeatchboq(value);
    console.log("state.masterServices.data ", state.masterServices.data);

    setFilteredCategory(
      state.category.filter((catItem) =>
        state.masterServices.data
          .filter((item) => item.masterServicename === value)
          .some((value) => value.catids === catItem.parent_id)
      )
    );
  };

  const selectchannelHandler = (value) => {
    console.log(
      state.masterServices.data.filter(
        (item) => item.masterServicename === value
      )
    );
    console.log(value);
    setFilteredCategory(
      state.category.filter((catItem) =>
        state.services
          .filter((item) => item.serviceName === value)
          .some((value) => value.category_id === catItem.parent_id)
      )
    );
    //   setFilteredCategory(
    //     filteredCategory.filter((catItem) => catItem.name === value)
    //   );
  };
  console.log("filtered ", filteredCategory);
  const handleEdit = async (values) => {
    console.log(values);
    // console.log(isEdit);
    try {
      setIsLoading(true);
      const response = await axios.patch(
        "category/edit-category-name",
        {
          id: isEdit.id,
          name: values.category,
          partnerName: state.partnerName,
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      setIsLoading(false);
      setIsEdit("");
      setSuccessEdit(true);
      editCategory(isEdit.id, values.category);

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleCategory = (item) => {
    console.log("ITEM TOGGLE ", item);
    toggleItem(item);
  };
  console.log(successMessage);

  // console.log(
  //   state.masterServices.data.filter(
  //     (master) => master.masterServicename === searchboq
  //   )
  // );

  return (
    <CategoryContainer>
      <div className="add-category">
        <span onClick={() => setIsModalVisible(true)}>
          <h5>Add Series</h5>
          <Tooltip title="Add Series">
            {<IoIosAddCircle size={"20px"} className="add-icon" />}
          </Tooltip>
        </span>
      </div>

      <Modal
        heading="Add Series"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        formSubmitHandler={formSubmitHandler}
        isLoading={isLoading}
        error={error}
        successMessage={successMessage}
      >
        <Form form={form} onFinish={handleSubmit} autoComplete="off">
          <Form.Item
            name="category"
            label="Enter Series"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input placeholder="Enter Series Name" />
          </Form.Item>
        </Form>
      </Modal>

      {confirm ? (
        <Modal
          heading="Do you want to confirm to Delete this"
          visible={true}
          onOk={confirmDelete}
          onCancel={handleCancel}
          // formSubmitHandler={formSubmitHandler}
          isLoading={isLoading}
          error={error}
          // successMessage={successMessage}
        ></Modal>
      ) : null}

      {successEdit ? (
        <Modal
          heading="Successfully Edited Category"
          visible={true}
          onOk={() => setSuccessEdit(false)}
          onCancel={handleCancel}
          // formSubmitHandler={formSubmitHandler}
          isLoading={isLoading}
          error={error}
          showSubmit={false}
          // successMessage={successMessage}
        ></Modal>
      ) : null}

      {isEdit ? (
        <Form form={form} onFinish={handleEdit} autoComplete="off">
          <Modal
            heading="Edit Category"
            visible={true}
            onOk={form.submit}
            onCancel={handleCancel}
            // formSubmitHandler={formSubmitHandler}
            isLoading={isLoading}
            error={error}
            // successMessage={successMessage}
          >
            <Form.Item
              name="category"
              label="Enter Category"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input placeholder={isEdit.name} />
            </Form.Item>
          </Modal>
        </Form>
      ) : null}

      <div className="main">
        <div className="search">
          <h3>Select Bouquet </h3>
          <Select
            onChange={selectbouquetHandler}
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Search bouquet"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {state.masterServices.masterServicesName !== undefined
              ? state.masterServices.masterServicesName.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))
              : null}
          </Select>
          {/* // select chanel */}
          {/* <Select
            onChange={selectchannelHandler}
            showSearch
            style={{
              width: 200,
              padding: "0 10px",
            }}
            placeholder="Search Channel"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {state.masterServices.masterServicesName !== undefined
              ? state.services.map((catItem, index) =>
                  state.masterServices.data
                    .filter((master) => master.masterServicename === searchboq)
                    .map((value, inx) =>
                      value.catids === catItem.category_id ? (
                        <Option value={catItem.serviceName} key={inx}>
                          {catItem.serviceName}
                        </Option>
                      ) : null
                    )
                )
              : null}
          </Select> */}
        </div>

        {filteredCategory.length < 1 ? (
          <div className="empty_table">
            <h3>Select Options above</h3>
          </div>
        ) : (
          <CategoryTable
            items={filteredCategory}
            deleteitem={onDelete}
            editItem={(data) => setIsEdit(data)}
            toggleCategory={(data) => toggleCategory(data)}
            fullItem={state.category}
          />
        )}

        {state.loader ? <OverLaySpinner size={100} /> : null}
      </div>
    </CategoryContainer>
  );
};

export default Category;

const CategoryContainer = styled.div`
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  .add-category {
    min-height: 50px;
    min-width: 100%;
    background-color: var(--ndoto-header-bg);
    /* background-color: rgba(1, 1, 1, 0.6); */
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    h5 {
      color: white;
      padding: 0 10px;
      :hover {
        color: cyan;
      }
    }
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      :hover {
        color: cyan;
      }
    }
  }
  .empty_table {
    padding: 20px;

    h3 {
      font-weight: 800;
    }
  }
  .main {
    /* position: relative; */
    /* background-color: red; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    h3 {
      display: inline-block;
    }
    .search {
      h3 {
        padding: 0 20px;
        color: white;
      }
    }
  }
  .home-icon {
    cursor: pointer;
  }
`;
