import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Favicon from "react-favicon";

import UserState from "./context/user/UserState";

import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import GlobalStyles from "./components/Global";
import Navigations from "./components/Navigations/Navigations";

// ReactDOM.render(
//   <React.StrictMode>
//     <GlobalStyles />
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );
const container = document.getElementById("root");
const root = createRoot(container);
// console.log("process.env.NODE_ENV  ", process.env.NODE_ENV);
// if (process.env.NODE_ENV !== "development") console.log = () => {};
root.render(
  <UserState>
    {/* <div> */}
    {/* <Favicon url="http://oflisback.github.io/react-favicon/img/github.ico" /> */}
    {/* </div> */}

    <GlobalStyles />
    <Router>
      <Navigations />
      <CookiesProvider>
        <App tab="home" />
      </CookiesProvider>
    </Router>
  </UserState>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
