import React from "react";
import styled from "styled-components";
import { Spin } from "antd";

const OverLaySpinner = ({ size, modal }) => {
  return (
    <OveryLayStyled modal={modal}>
      <Spin className="spin" size={size} />
    </OveryLayStyled>
  );
};

export default OverLaySpinner;

const OveryLayStyled = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: ${(props) => (props.modal ? 0 : "60px")};
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);

  .spin {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  }
`;
