import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <FooterStyled>
      <p>
        &copy; All Right Reserved {new Date().getFullYear()}-
        {new Date().getFullYear() + 1}
      </p>
    </FooterStyled>
  );
};

export default Footer;

const FooterStyled = styled.div`
  min-height: 8vh;
  position: sticky;
  left: 0;
  /* min-width: 100vw; */
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
