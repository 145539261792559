import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { AiOutlineMenu } from "react-icons/ai";
import { FcHome } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { IoIosAddCircle } from "react-icons/io";

import userContext from "../../context/user/userContext";
// import ServicesTable from "../../components/ServicesTable/ServicesTables";
import Spinner from "../../components/Spinner/Spinner";
import MasterServiceTable from "../../components/MasterServieTable/MasterServieTable";
const MasterServices = () => {
  const navigate = useNavigate();
  const {
    state,

    updateLoader,
    // removeToken,
    // addOperator,
    // addProductsItems,aaj dikh
    // deleteProduct,
    // updateProductItems,
    sideBarOpen,
    deleteServices,
  } = useContext(userContext);

  const [items, setItems] = useState([]);
  const { masterServices, category } = state;
  // console.log("masterServices ", masterServices);
  // console.log("state services ", state.services);

  const onDelete = async (id) => {
    try {
      updateLoader(true);
      console.log(" DELETE ", id);

      const response = await axios.delete(
        "/services/removeServices",
        {
          data: {
            id,
            partnerName: state.partnerName,
          },
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      deleteServices(id);
      console.log("response ", response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
      alert("SOME THING Went wrong try again later");
    }
  };

  const { sideBar } = state;
  // console.log("SIDEBAR", sideBar);

  useEffect(() => {
    if (!sideBar) sideBarOpen();
  }, []);

  useEffect(() => {
    // console.log("HEEREEE", masterServices);
    if (state.services.length > 0 && masterServices.data !== undefined) {
      // console.log("masterServices ", masterServices);
      if (items.length > 0) setItems([]);

      setItems(
        state.services.filter((el) => {
          return state.masterServices.data.find((element) => {
            return element.category_id === el.catids;
          });
        })
      );

      // state.services.forEach((item) => {
      //   state.masterServices.data.forEach((masterItem) => {
      //     console.log(item);
      //     //   console.log(item);
      //     if (item.category_id === masterItem.catids) {
      //       // console.log(item);
      //       items.push({
      //         masterServiceName: masterItem.masterServicename,
      //         ...item,
      //       });
      //     }
      //     // items.push({ ...item, serviceName: masterItem.serviceName });
      //   });
      // });
    }
  }, [state.services, state.masterServices]);

  //   state.services.filter((f) =>
  //     masterServices.data.findAll((item) => item.catids === f.category_id)
  //   );

  //   masterServices.data.find((masterItem) =>
  //         masterItem.catids === serviceItem.category_id
  //           ? serviceItem.serviceName
  //           : null
  //       )
  console.log("ITEMS geer", items);
  return (
    <ServicesContainer sideBar={sideBar}>
      <div className="add-product">
        {/* <span onClick={() => setIsModalVisible(true)}> */}
        {/* <span onClick={() => navigate("/add-service")}>
          <h5>Add Service</h5>
          <Tooltip title="Add Service">
            {<IoIosAddCircle size={"20px"} className="add-icon" />}
          </Tooltip>
        </span> */}
      </div>
      <MasterServiceTable
        // items={masterServices.data.map((masterItem) => {
        //   return {
        //     masterServicename: masterItem.masterServicename,
        //     masterId: masterItem.masterId,
        //   };
        // })}

        items={items}
        deleteitem={onDelete}
        editItem={(data) => navigate(`/edit-service/${data.id}`)}
        subCategories={category}
      />
      {state.loader && <Spinner />}
    </ServicesContainer>
  );
};

export default MasterServices;

const ServicesContainer = styled.div`
  height: 100%;

  .add-product {
    min-height: 50px;
    min-width: 80%;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        color: cyan;
      }
      h5 {
        color: white;
        padding: 0 10px;
        :hover {
          color: cyan;
        }
      }
    }
  }
`;
