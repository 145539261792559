import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
// import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Row, Col } from "antd";
import { IoIosAddCircle } from "react-icons/io";
import { UploadOutlined } from "@ant-design/icons";
import { FcHome } from "react-icons/fc";

import { useNavigate } from "react-router-dom";
import { Tooltip, Input, Form, Upload, Select, Button } from "antd";

// import ImgCrop from "antd-img-crop";
import Modal from "../../components/Modal/Modal";
import userContext from "../../context/user/userContext";
import ProductTable from "../../components/ProductTable/ProductTable";
import OverLaySpinner from "../../components/OverlaySpinner";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 5,
  },
};

const AddProduct = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [successEdit, setSuccessEdit] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedMainService, setSelectedMainService] = useState("");
  //   const [duration, setDuration] = useState(0);
  const [uploadType, setUploadType] = useState("upload");
  const [successAdded, setSuccessAdded] = useState(false);
  const [subServiceChanges, setSubServiceChanged] = useState([]);
  const [serviceChanged, setServiceChanged] = useState([]);
  const navigate = useNavigate();

  let country = "";
  let menu = "";

  const {
    state,
    updateLoader,
    removeToken,
    addOperator,
    addProductsItems,
    deleteTeaser,
    updateProductItems,
    sideBarOpen,
    addTeaserItems,
    updateTeaserItems,
  } = useContext(userContext);

  const { sideBar } = state;

  // console.log("STATE ", state);

  // const handleSubmitUrl = async (values) => {
  //   const {
  //     title,
  //     operator,
  //     country,
  //     category,
  //     description,
  //     image,
  //     video,
  //     duration,
  //   } = values;

  //   try {
  //     updateLoader(true);
  //     const catId = state.category.find((item) => {
  //       console.log(item);
  //       console.log(category);
  //       setIsLoading(true);
  //       if (item.name === category) return item.id;
  //     });
  //     console.log("CAT ID ", catId);
  //     // console.log("values.image.originFileObj ", values.video[0]);

  //     const formData = new FormData();
  //     formData.append("partnerName", state.partnerName);
  //     formData.append("image", image);
  //     formData.append("video", video);
  //     formData.append("title", title);
  //     formData.append("operator", operator);
  //     formData.append("country", country);
  //     formData.append("description", description);
  //     formData.append("catId", catId.id);
  //     formData.append("duration", duration);
  //     formData.append("status", 0);

  //     // formData.append("age", 20);

  //     // console.log("formData ", formData);

  //     console.log(values);

  //     const response = await axios.post(
  //       "/addProducturl/addItem",
  //       {
  //         image,
  //         partnerName: state.partnerName,
  //         video,
  //         title,
  //         operator,
  //         country,
  //         description,
  //         catId: catId.id,
  //         duration,
  //         status: 0,
  //       },

  //       {
  //         headers: { Authorization: `Bearer ${state.jsonToken}` },
  //         "content-type": "multipart/form-data",
  //       }
  //     );

  //     const responseFetch = await axios.get(
  //       `/product/fetch-products/?partner=${state.partnerName}`,

  //       {
  //         headers: { Authorization: `Bearer ${state.jsonToken}` },
  //         "content-type": "multipart/form-data",
  //       }
  //     );
  //     console.log("fetch-products ", responseFetch.data.message);
  //     updateLoader(false);
  //     addProductsItems(responseFetch.data.message);
  //     setIsLoading(false);
  //     setSuccessAdded(true);

  //     console.log("RESPONSE ", response);
  //   } catch (err) {
  //     updateLoader(false);
  //     setIsLoading(false);
  //     alert("Some thing went wrong pleaase try again later");
  //   }
  // };

  const handleSubmit = async (values) => {
    const {
      title,
      category,
      description,
      image,
      video,
      //   duration,
    } = values;

    try {
      console.log(values);
      console.log("serice changed ", serviceChanged);
      console.log("subServiceChanges changed ", subServiceChanges);

      updateLoader(true);
      //   console.log("CAT ID ", catId);
      //   console.log("values.image.originFileObj ", values.image);

      if (image.fileList.length !== video.length) {
        updateLoader(false);
        alert("Images and Videos a should be Same");
      }

      const formData = new FormData();
      formData.append("partnerName", state.partnerName);
      //   formData.append("image", values.image.fileList.originFileObj);
      image.fileList.forEach((item) => {
        console.log(item);
        //   formData.append("image", values.image.fileList.originFileObj);
        formData.append("image", item.originFileObj);
      });
      video.forEach((item) => {
        console.log(item);
        formData.append("video", item.originFileObj);
      });

      //   formData.append("video", values.video[0].originFileObj);
      formData.append("title", title);
      //   formData.append("operator", operator);
      formData.append("country", country);
      formData.append("description", description);
      formData.append("catId", serviceChanged);
      formData.append("subcategory", subServiceChanges);
      // formData.append("sub");
      //   formData.append("duration", duration);
      formData.append("status", 0);

      // formData.append("age", 20);

      // console.log("formData ", formData);

      console.log(values);

      const response = await axios.post(
        "/teasers/add-teaser",
        formData,
        // {
        //   title,
        //   operator,
        //   country,
        //   category,
        //   description,
        // },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      updateLoader(false);

      const responseFetch = await axios.get(
        `/teasers/fetch-teasers/?partner=${state.partnerName}`,

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      console.log("fetch-products ", responseFetch.data.message);
      addTeaserItems(responseFetch.data.message);
      setIsLoading(false);
      setSuccessAdded(true);

      console.log("RESPONSE ", response);
    } catch (err) {
      //   console.log(err.message);
      setIsLoading(false);
      updateLoader(false);
      //   navigate("/login");
      alert("Some thing went wrong pleaase try again later");
    }
  };

  // useEffect(() => {
  //   const fetchProducts = async (jsonToken) => {
  //     // updateLoader(true);
  //     const response = await axios.get("/product/fetch-products", {
  //       headers: { Authorization: `Bearer ${state.jsonToken}` },
  //     });

  //     console.log("response ", response.data.message);

  //     return await response.data.message;
  //   };
  //   if (state.jsonToken) {
  //     fetchProducts(state.jsonToken)
  //       .then((item) => {
  //         // console.log("jo");
  //         addProductsItems(item);
  //         updateLoader(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         // navigate("/login");
  //         updateLoader(false);
  //         removeToken();
  //       });
  //   }
  // }, [state.jsonToken]);

  //   useEffect(() => {
  //     // console.log(" ADD opeartor");
  //     const fetchOperator = async (jsonToken) => {
  //       // updateLoader(true);
  //       const response = await axios.get("/operator/fetch-operator", {
  //         headers: { Authorization: `Bearer ${state.jsonToken}` },
  //       });

  //       return await response.data.data;
  //     };

  //     if (state.jsonToken) {
  //       fetchOperator(state.jsonToken)
  //         .then((item) => {
  //           // console.log("jo");
  //           addOperator(item);
  //           updateLoader(false);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           // navigate("/login");
  //           updateLoader(false);
  //           removeToken();
  //         });
  //     }
  //   }, [state.jsonToken]);

  //   if (state.operator.length > 0 && state.category.length > 0) {
  //     // var menu = <Option value="">Jack</Option>;
  //     menu = state.operator.map((item, index) => (
  //       <Option key={index} value={item.name}>
  //         {item.name}
  //       </Option>
  //     ));

  //     country = state.operator.map((item, index) => {
  //       console.log(" ITEM ", item, selectedOperator);
  //       if (item.name === selectedOperator)
  //         return (
  //           <Option key={index} value={item.name}>
  //             {item.country}
  //           </Option>
  //         );
  //     });
  var catItem = state.category.map((item, index) => (
    <Option key={index} value={item.name}>
      {item.name}
    </Option>
  ));

  const toggleItem = async (status, id) => {
    console.log(status, id);

    try {
      updateLoader(true);
      // console.log(" DELETE ", id);

      const response = await axios.post(
        "/teasers/toggleProduct",
        {
          id,
          status,
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      console.log("response ", response.data.message);
      updateTeaserItems(response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
    }
  };
  const chosenVideoHandler = (e) => {
    console.log(e);
    var file = e.file;
    // console.log(" files", file);

    var video = document.createElement("video");
    video.preload = "metadata";

    // video.onloadedmetadata = function () {
    //   console.log(" files", file);
    //   window.URL.revokeObjectURL(video.src);
    //   // console.log("video ", video.duration);
    //   setDuration(video.duration);

    //   if (video.duration < 1) {
    //     console.log("Invalid Video! video is less than 1 second");
    //     return;
    //   }
    // };

    // video.src = URL.createObjectURL(file);
    // console.log("duration ", duration);
  };
  // console.log("state :", state);
  const handleCancel = () => {
    setIsModalVisible(false);
    setVisible(false);
    setIsLoading(false);
    setError("");
    setSuccessMessage("");
    setConfirm(false);
    setIsEdit("");

    form.resetFields();
  };

  const formSubmitHandler = () => {
    console.log("Submit");
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const onChange = ({ fileList: newFileList }) => {
    console.log(fileList);
    setFileList(newFileList);
  };

  function handleChangeServiceChange(value) {
    console.log(console.log(`selected ${value}`));
    setServiceChanged(value);
  }
  // console.log("state :", state);
  function handleChange(value) {
    console.log(`selected ${value}`);
    setSelectedOperator(value);
  }

  // console.log("fileList ", fileList);
  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const onDelete = async (id) => {
    try {
      updateLoader(true);
      console.log(" DELETE ", id);

      const response = await axios.delete(
        "/teasers/remove-products",
        {
          data: {
            id,
            partnerName: state.partnerName,
          },
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      deleteTeaser(id);
      console.log("response ", response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
      alert("SOME THING Went wrong try again later");
    }
  };

  function handleChangeUpload(value) {
    console.log(`selected ${value}`);
    setUploadType(value);
  }
  function handlesubServiceChange(value) {
    console.log("handlesubServiceChange ", value);
    setSubServiceChanged(value);
  }

  function handleChangeMainService(value) {
    console.log(`selected ${value}`);
    setSelectedMainService(value);
  }
  console.log(" teasers ", state.teasers);
  return (
    <AddProductContainer sideBar={sideBar}>
      <div className="add-product">
        <span onClick={() => setIsModalVisible(true)}>
          <h5>Add Teasers</h5>
          <Tooltip title="Add Teaser">
            {<IoIosAddCircle size={"20px"} className="add-icon" />}
          </Tooltip>
        </span>
        <Modal
          heading="Add Teaser"
          visible={isModalVisible}
          onOk={form.submit}
          onCancel={handleCancel}
          formSubmitHandler={formSubmitHandler}
          isLoading={isLoading}
          error={error}
          successMessage={successMessage}
          width={1000}
        >
          <Form
            form={form}
            // onFinish={uploadType === "upload" ? handleSubmit : handleSubmitUrl}
            onFinish={handleSubmit}
            autoComplete="off"
            // {...formItemLayout}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Enter Title"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Enter Description"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter Description"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Col span={10}>
                  <Form.Item
                    name="mainService"
                    label="Enter Bouquite Name"
                    rules={[
                      { required: true, message: "This field is required." },
                    ]}
                  >
                    <Select
                      placeholder="Select Bouquite"
                      onChange={handleChangeMainService}
                    >
                      {state.masterServices.masterServicesName !== undefined &&
                        state.masterServices.masterServicesName.map(
                          (item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          )
                        )}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    name="category"
                    label="Enter Channel"
                    rules={[
                      { required: true, message: "This field is required." },
                    ]}
                  >
                    <Select
                      placeholder="select Channel"
                      onChange={handleChangeServiceChange}
                    >
                      {/* handleChangeServiceChange */}
                      {state.masterServices.masterServicesName !== undefined &&
                        state.services.map((catItem) =>
                          state.masterServices.data
                            .filter(
                              (master) =>
                                master.masterServicename === selectedMainService
                            )
                            .map((data, index) =>
                              data.catids === catItem.category_id ? (
                                <Option key={index} value={catItem.category_id}>
                                  {catItem.serviceName}
                                </Option>
                              ) : null
                            )
                        )}
                    </Select>
                  </Form.Item>
                </Col>
              </div>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Col span={24}>
                  <Form.Item
                    name="subcategory"
                    label="Choose Series"
                    rules={[
                      { required: true, message: "This field is required." },
                    ]}
                  >
                    <Select
                      placeholder="select Series"
                      onChange={handlesubServiceChange}
                    >
                      {state.category.map((item) =>
                        state.services
                          .filter(
                            (service) => service.category_id === serviceChanged
                          )
                          .map((data, inx) =>
                            data.category_id === item.parent_id ? (
                              <Option key={data.name} value={item.sub_cat_id}>
                                {item.name}
                              </Option>
                            ) : null
                          )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </div>
            </Row>

            {uploadType == "upload" ? (
              <Form.Item
                name="video"
                label="Upload Video"
                valuePropName="fileList"
                getValueFromEvent={normFile}

                // extra="longgggggggggggggggggggggggggggggggggg"
              >
                <Upload
                  accept="video/*"
                  name={"video"}
                  listType="picture"
                  customRequest={dummyRequest}
                  maxCount={5}
                  // onChange={chosenVideoHandler}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            ) : uploadType == "url" ? (
              <Form.Item
                name="video"
                label="Enter Video Url"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Enter url" type="text" />
              </Form.Item>
            ) : null}

            {uploadType === "upload" ? (
              <Form.Item name="image" label="Upload Image">
                <Upload
                  name={"image"}
                  accept=".png, .jpg, .jpeg"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  maxCount={1}
                  customRequest={dummyRequest}
                >
                  {fileList.length < 6 && "+ Upload"}
                </Upload>
                {/* </ImgCrop> */}
              </Form.Item>
            ) : uploadType == "url" ? (
              <Form.Item
                name="image"
                label="Enter Image Url"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Enter url" type="text" />
              </Form.Item>
            ) : null}
          </Form>
          {state.loader ? <OverLaySpinner size={500} modal={true} /> : null}
        </Modal>
      </div>
      {successAdded ? (
        <Modal
          heading="Successfully Added Product"
          visible={true}
          onOk={() => {
            setSuccessAdded(false);
            setIsModalVisible(false);
            setVisible(false);
            form.resetFields();
            setFileList([]);
          }}
          onCancel={handleCancel}
          // formSubmitHandler={formSubmitHandler}
          isLoading={isLoading}
          error={error}
          showSubmit={false}
          // successMessage={successMessage}
        ></Modal>
      ) : null}
      <div className="main">
        {state.teasers.length >= 0 && (
          <ProductTable
            items={state.teasers}
            // deleteitem={onDelete}
            deleteitem={onDelete}
            editItem={(data) => setIsEdit(data)}
            toggleItem={toggleItem}
          />
        )}

        {state.loader ? <OverLaySpinner size={100} /> : null}
      </div>
    </AddProductContainer>
  );
};

export default AddProduct;

const AddProductContainer = styled.div`
  /* background-color: yellow; */

  .main {
    min-height: 90vh;
  }
  .add-product {
    min-height: 50px;
    min-width: 100%;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        color: cyan;
      }
      h5 {
        color: white;
        padding: 0 10px;
        :hover {
          color: cyan;
        }
      }
    }
  }
`;
