import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
html,
body {
    padding: 0;
    margin: 0;
    background-color:rgba(0,0,0,0.8) ;
    background-color:#faf8f3 ;
    font-family: 'DM Sans', sans-serif;
    /* font-family: 'Lato', sans-serif; */
    /* font-family: 'Bubblegum Sans', cursive; */
    /* font-family: 'Poppins', sans-serif; */

}


a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
  
}


:root {
   --ndoto-header-bg:rgb(2,0,36);
   --ndoto-primary-white:rgba(255,255,255, 0.8);
   --ndoto-button-bg-color:red;

   --white-color:white
  }
  .slick-slide {
  padding: 0 10px;
  
}
.slick-list {
  margin: 0 -10px;
  
}

.ant-modal-content {
    overflow: auto;
    border-radius: 10px
}




`;

export default GlobalStyles;
