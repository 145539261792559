import React, { Suspense, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginComponent from "../../components/Login/";
import styled from "styled-components";
import background from "../../assets/images/login-background.png";
import axios from "axios";

import userContext from "../../context/user/userContext";
import OverLaySpinner from "../../components/OverlaySpinner";
const Login = () => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const state = useContext(userContext);

  const navigate = useNavigate();

  // console.log("state ", state);

  const formHandler = async ({ username, password }) => {
    try {
      setLoader(true);
      console.log(username, password);
      // const userLogin = await axios.post("http://localhost:9003/user/login", {
      const userLogin = await axios.post("/user/login", {
        username: username,
        password: password,
      });

      window.localStorage.setItem(
        "token",
        JSON.stringify(userLogin.data.token)
      );
      setLoader(false);
      // await window.localStorage.setItem("token",JSON)
      console.log(" userLogin response ", userLogin.data);
      state.updateUser(
        username,
        userLogin.data.token,
        userLogin.data.partnerName
      );
      navigate("/dashboard");
    } catch (err) {
      setLoader(true);
      console.log(err.response.data.data);
      setLoader(false);
      setError(err.response.data.data);
    }
    // if
  };
  return (
    <LoginStyled background={background}>
      <Suspense fallback={"Loading..."}>
        <div className="login-form">
          <div className="login-item">
            <h5>Content Uploading</h5>
            <LoginComponent
              formHandler={formHandler}
              loader={loader}
              error={error}
            />
          </div>
        </div>
      </Suspense>
    </LoginStyled>
  );
};

export default Login;

const LoginStyled = styled.div`
  height: 100vh;
  width: 100vw;
  /* background: url("../../assets/images/login-background.png") center no-repeat; */
  position: relative;
  background-image: linear-gradient(
    to bottom,
    #922764,
    #6f2960,
    #4d2854,
    #312343,
    #1c1b2e,
    #171b2a,
    #151b25,
    #151a1f,
    #19252b,
    #1d3035,
    #213b3e,
    #284745
  );

  h5 {
    color: white;
    font-size: 30px;
    text-align: center;
    margin-left: 40px;
  }
  .login-form {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
  }
  .login-item {
    width: 80%;
    text-align: start;
  }

  @media (max-width: 640px) {
    .login-form {
      width: 80%;
      height: 80%;
      justify-content: center;
    }
    .login-item {
      width: 70%;
    }
  }
`;
