import {
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Input,
  Switch,
  Button,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player/lazy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import "./ProductTable.css";
import axios from "axios";

const props = {
  name: "file",
  // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  // headers: {
  //   authorization: "authorization-text",
  // },

  beforeUpload: (file) => {
    console.log(file.type);
    const isPNG = file.type === "image/png" || "image/jpeg" || "image/jpg";

    if (!isPNG) {
      message.error(`${file.name} is not a png file`);
    }

    return isPNG || Upload.LIST_IGNORE;
  },
  onChange(info) {
    console.log(info);
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,

  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ProductTable = ({
  items,
  deleteitem,
  editItem,
  toggleItem,
  editImage,
}) => {
  // console.log("ITEMS ", items);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [disabled, setDisabled] = useState(false);

  const isEditing = (record) => record.key === editingKey;
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    //setting data
    // setData({...items,key:});
    // data.length <= 0 &&
    if (data.length > 0) setData([]);
    items.map((item) => {
      // console.log(item);
      setData((prevState) => [...prevState, { ...item, key: item.id }]);
    });
  }, [items]);

  useEffect(() => {
    // console.log("HERE EDIT", editingKey);
    // Settin columns
    const tempColumn = [];
    if (items.length > 0 && tempColumn.length <= 0)
      items.forEach((item) => tempColumn.push(Object.keys(item)));

    // For columns
    if (items.length > 0 && columns.length <= 0)
      tempColumn[0].map((item) =>
        setColumns((prevState) => [
          ...prevState,

          {
            title: item,
            dataIndex: item,
            key: item,
            editable: (item === "name" || item === "description") && true,
            // render: () => <img src={item.imgurl} />,

            sorter: (a, b) => a.name.localeCompare(b.name),
            align: "center",
            width:
              item === "id" ||
              item === "category_id" ||
              item === "duration" ||
              item === "status"
                ? 100
                : item === "imgurl" || item === "vurl"
                ? 400
                : 150,
          },
        ])
      );

    // console.log(columns);
    if (items.length > 0)
      setColumns((prevState) => [
        ...prevState.filter(
          (item) =>
            item.title !== "imgurl" &&
            item.title !== "vurl" &&
            item.title !== "status" &&
            item.title !== "category_id" &&
            item.title !== "sub_category_id" &&
            item.title !== "id" &&
            item.title !== "Image" &&
            item.title !== "Video" &&
            item.title !== "Disable/Enable" &&
            item.title !== "operation" &&
            item.title !== "partner"
        ),
        {
          align: "center",
          width: 200,
          title: "Image",
          key: "operation",
          // fixed: "right",

          render: (_, record) => (
            <div className="table-image">
              <a href={record.imgurl} target="_blank">
                <img src={record.imgurl} />
              </a>
              <Upload
                // {...props}
                customRequest={dummyRequest}
                accept=".png, .jpg, .jpeg"
                onChange={(info) =>
                  editImage(record.key, info.file.originFileObj)
                }
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{ margin: "5px", width: "100%" }}
                >
                  Change
                </Button>
              </Upload>
            </div>
          ),
        },
        {
          align: "center",
          width: 200,
          title: "Video",
          key: "operation",
          // fixed: "right",

          render: (_, record) => (
            <div className="table-video">
              <ReactPlayer
                url={record.vurl}
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          ),
        },
        {
          align: "center",
          width: 100,
          title: "operation",
          dataIndex: "operation",
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <Typography.Link
                  onClick={() => save(record.key)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            );
          },
        },
        {
          align: "center",
          width: 200,
          title: "Disable/Enable",
          key: "operation",
          // fixed: "right",

          render: (_, record) =>
            items.length > 0 ? (
              <a
              // style={{
              //   backgroundColor: "red",
              //   padding: "10px",
              //   borderRadius: "5px",
              //   color: "white",
              // }}
              >
                <Switch
                  size="small"
                  defaultChecked={record.status}
                  onChange={(checked, e) => onChanged(checked, record.key, e)}
                  // onChange={onChanged}
                />
              </a>
            ) : (
              <h1>Hi</h1>
            ),
        },
      ]);

    //others now
  }, [items, editingKey]);

  const onChanged = (checked, key) => {
    console.log(checked, key);
    // console.log("checked", key);
    setDisabled(checked);
    toggleItem(checked, key);
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      description: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    // console.log("SAVE KEY ", data.filter((item) => item.id === key)[0]);

    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      console.log("index ", index);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      // console.log(newData);

      editItem(newData.filter((item) => item.id === key)[0]);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  // useEffect(() => {
  //   editItem(data.filter((item) => item.id === key)[0]);
  // }, [data]);
  return (
    <TableStyled>
      <Form form={form} component={false}>
        <Table
          scroll={{ x: 1500, y: 300 }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </TableStyled>
  );
};

export default ProductTable;

const TableStyled = styled.div`
  width: 90%;

  /* height: 100vh; */
  margin: auto;
  margin-top: 20px;

  .ant-table-body {
    min-height: 500px;
    max-height: 700px !important;
  }
  .table-image {
    height: 100px;
    width: 100px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .table-video {
    height: 150px;
    width: 150px;
    .video-item {
      /* height: 100%;
      width: 100%; */
    }
  }
  /* text-align: center; */
`;
