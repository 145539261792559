import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
// import { AiOutlineMenu } from "react-icons/ai";
// import { FcHome } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Input,
  Form,
  Upload,
  Select,
  Button,
  Row,
  Col,
  Checkbox,
} from "antd";
import { BsFillSkipBackwardCircleFill } from "react-icons/bs";

import userContext from "../../context/user/userContext";
import axios from "axios";
import Spinner from "../../components/Spinner/Spinner";
const AddServiceCategories = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const {
    state,
    updateLoader,
    removeToken,
    addOperator,
    addProductsItems,
    deleteProduct,
    updateServices,
    sideBarOpen,
  } = useContext(userContext);
  const [form] = Form.useForm();
  const { sideBar } = state;
  const [fileList, setFileList] = useState([]);
  const [loader, setLoader] = useState(false);
  const onChange = ({ fileList: newFileList }) => {
    console.log(fileList);
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const handleSubmit = async (values) => {
    console.log(values);

    console.log(" DATA ", values.image.file.originFileObj);

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("mainService", values.mainService);

    formData.append("partnerName", state.partnerName);
    formData.append("image", values.image.file.originFileObj);

    setLoader(true);
    try {
      const response = await axios.post("/services/create", formData, {
        headers: { Authorization: `Bearer ${state.jsonToken}` },
        "content-type": "multipart/form-data",
      });
      setLoader(false);
      updateServices(response.data.message);
      form.resetFields();
      setFileList([]);
      alert("Channel Added Successfully");
      navigate("/service-categories");
    } catch (err) {
      setLoader(false);
      alert("Some thing went wrong ", err);
    }
    // values.image.file.originFileObj
  };

  // console.log("state.category ", state.category);

  const onChangeCheckBox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <ServicesContainer sideBar={sideBar}>
      <div className="add-product">
        {/* <span onClick={() => setIsModalVisible(true)}> */}
        <span onClick={() => navigate("/services")}>
          <h5>Go Back</h5>
          <Tooltip title="Show All services">
            {
              <BsFillSkipBackwardCircleFill
                size={"30px"}
                className="add-icon"
              />
            }
          </Tooltip>
        </span>
      </div>

      <div className="add-container">
        <h3 style={{ textAlign: "center", padding: "20px", fontWeight: 800 }}>
          Create Channel (Beauty TV, Faith First)
        </h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
            style={{ width: "100%" }}
            // {...formItemLayout}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Enter Channel Name"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                  // style={{ width: 500 }}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
              </Col>
            </Row>

            {state.masterServices.masterServicesName !== undefined ? (
              <Row>
                <Col span={12}>
                  <div
                    style={{
                      // padding: 1,
                      // height: 0,
                      // background: "#eee",
                      position: "relative",
                    }}
                    id="area1"
                  >
                    <Form.Item
                      name="mainService"
                      label="Select Main Service"
                      rules={[
                        {
                          required: true,
                          message: "Please select your Service",
                          // type: "array",
                        },
                      ]}
                    >
                      <Select
                        // mode="single"
                        placeholder="Please select Main Service"
                        getPopupContainer={() =>
                          document.getElementById("area1")
                        }
                      >
                        {state.masterServices.masterServicesName.map(
                          (item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col span={12}>
                <Form.Item
                  name="mainService"
                  label="Want to add Series?"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Service",
                      // type: "array",
                    },
                  ]}
                >
                  <Checkbox onChange={onChangeCheckBox}></Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  name="image"
                  label="Upload Logo of Service"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Upload
                    name={"image"}
                    accept=".png, .jpg, .jpeg"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    // maxCount={1}
                    customRequest={dummyRequest}
                  >
                    {fileList.length < 1 && "+ Upload"}
                  </Upload>
                  {/* </ImgCrop> */}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                span: 12,
                offset: 6,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {loader && <Spinner />}
    </ServicesContainer>
  );
};

export default AddServiceCategories;

const ServicesContainer = styled.div`
  height: 100%;
  /* background-image: linear-gradient(
    to right bottom,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  ); */
  .ant-upload-list {
    display: block;
  }
  .add-product {
    min-height: 50px;
    min-width: 100%;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        color: cyan;
      }
      h5 {
        color: white;
        padding: 0 10px;
        :hover {
          color: cyan;
        }
      }
    }
  }
  .add-container {
    height: 100%;
    border: 1px solid black;
    width: 100%;
    /* min-height: 91vh; */
    padding: 20px;
  }
`;
