import React, { useState, useEffect } from "react";
import { Table, Input, Typography, Form, Popconfirm } from "antd";
import styled from "styled-components";
// import axios from "axios";

const ServicesTable = ({ items, deleteitem, editItem }) => {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState(items);
  const [form] = Form.useForm();

  //   title: "",
  //   dataIndex: "",

  //   key: "",

  const onDelete = (key, e) => {
    e.preventDefault();
    console.log(key);
    deleteitem(key);

    // const data = this.state.data.filter(item => item.key !== key);
    // this.setState({ data, isPageTween: false });
  };

  useEffect(() => {
    const tempColumn = [];
    console.log("items ", items);
    console.log("tempColumn.length ", tempColumn.length);
    if (items.length > 0 && tempColumn.length <= 0)
      items.forEach((item) => tempColumn.push(Object.keys(item)));

    if (items.length > 0 && columns.length <= 0)
      // for column
      tempColumn[0].map((item) => {
        // console.log(item);
        setColumns((prevState) => [
          ...prevState,

          {
            title: item,
            dataIndex: item,
            key: item,
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
        ]);
      });

    if (items.length > 0 && columns.length <= 0)
      setColumns((prevState) => [
        ...prevState.filter(
          (item) =>
            item.title != "logoUrl" &&
            item.title !== "category_id" &&
            item.title !== "sr_no"
        ),
        {
          title: "logoUrl",
          dataIndex: "logoUrl",
          key: "id" || "masterId",
          render: (text) => (
            <a target="_blank" rel="noopener noreferrer" href={text}>
              {text}
            </a>
          ),
        },
        {
          title: "Action",
          dataIndex: "",
          key: "id",
          render: (_, record) =>
            items.length > 0 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={(e) => onDelete(record.id, e)}
              >
                <a
                  style={{
                    backgroundColor: "red",
                    padding: "10px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  Delete
                </a>
              </Popconfirm>
            ) : (
              <h1>Hi</h1>
            ),
        },
        {
          title: "operation",
          dataIndex: "operation",
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <Typography.Link
                  style={{
                    marginRight: 8,
                  }}
                >
                  Save
                </Typography.Link>
              </span>
            ) : (
              <span
                onClick={() => editItem(record)}
                style={{
                  padding: "12px",
                  backgroundColor: "blue",
                  borderRadius: "5px",
                }}
              >
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                  style={{ color: "white" }}
                >
                  Edit
                </Typography.Link>
              </span>
            );
          },
        },
      ]);

    // for data source
    if (items.length >= 0) {
      setDataSource([]);
      items.map((item) => {
        // console.log(item);
        setDataSource((prevState) => [...prevState, item]);
      });
    }

    //   if(items.length>0)
  }, [items]);

  //   console.log(" items in table Cat ", columns);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    console.log("RECORD ", record);
    // editItem(record);
  };

  const search = (value) => {
    console.log("value ", value);
    // let value = evt.target.value;
    // const { baseData } = this.state;
    // console.log("PASS", evt.target.value);

    const filterTable = items.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    console.log("filterTable ", filterTable);

    setDataSource(filterTable);
  };

  return (
    <TableStyled>
      <Input.Search
        style={{ margin: "0 0 10px 0", width: "100%" }}
        placeholder="Search by..."
        enterButton
        // onChange={search}
        onSearch={search}
        allowClear
      />
      <Form form={form} component={false}>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          pagination={{ pageSize: 20 }}
          bordered
          scroll={{ x: 1500, y: 300 }}
        />
      </Form>
      ;
    </TableStyled>
  );
};

export default ServicesTable;

const TableStyled = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 20px;
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
  .ant-table-body {
    min-height: 500px;
    max-height: 600px !important;
  }
  table tr:hover {
    cursor: pointer;
  }
`;
