import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { Tooltip, Input, Form, Upload, Select, Button, Col, Row } from "antd";
import { BsFillSkipBackwardCircleFill } from "react-icons/bs";

import userContext from "../../context/user/userContext";
import axios from "axios";
import Spinner from "../../components/Spinner/Spinner";
const Services = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const {
    state,
    updateLoader,
    removeToken,
    addOperator,
    addProductsItems,
    deleteProduct,
    // updateServicesItems,
    updateNewCategory,
    sideBarOpen,
  } = useContext(userContext);
  const [form] = Form.useForm();
  const { sideBar } = state;
  const [fileList, setFileList] = useState([]);
  const [selectedMainService, setSelectedMainService] = useState("");
  const [loader, setLoader] = useState(false);
  const onChange = ({ fileList: newFileList }) => {
    console.log(fileList);
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  function handleChangeMainService(value) {
    console.log(`selected ${value}`);
    setSelectedMainService(value);
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const handleSubmit = async (values) => {
    console.log(values);
    let subItems = values.selectmultiple.map((item) =>
      state.category.find((data) => data.name === item)
    );
    // console.log(" DATA ", values.image.file.originFileObj);

    const formData = new FormData();
    formData.append("mainService", values.mainService);
    formData.append("partnerName", state.partnerName);
    // formData.append("image", values.image.file.originFileObj);

    subItems.forEach((item) => {
      console.log("item ", item);
      formData.append("categories", JSON.stringify(item));
    });

    setLoader(true);
    try {
      const response = await axios.post("/services/create-category", formData, {
        headers: { Authorization: `Bearer ${state.jsonToken}` },
        "content-type": "multipart/form-data",
      });
      setLoader(false);
      console.log("response.data.message ", response.data);
      response.data.result.forEach((element) => {
        console.log("cons element ", element);
        updateNewCategory(element);
      });

      form.resetFields();
      setFileList([]);
      navigate("/add-service");
      alert("Series Inserted Successfully ");
    } catch (err) {
      setLoader(false);
      alert("Some thing went wrong ", err);
    }
    // values.image.file.originFileObj;
  };

  console.log("state.category ", state.masterServices);
  return (
    <ServicesContainer sideBar={sideBar}>
      <div className="add-product">
        {/* <span onClick={() => setIsModalVisible(true)}> */}
        <span onClick={() => navigate("/services")}>
          <h5>Go Back</h5>
          <Tooltip title="Show All services">
            {
              <BsFillSkipBackwardCircleFill
                size={"30px"}
                className="add-icon"
              />
            }
          </Tooltip>
        </span>
      </div>

      <div className="add-container">
        <h3 style={{ textAlign: "center", padding: "20px" }}>
          Assign Series to Channel & Bouquet
        </h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
            style={{ width: "100%" }}
            // {...formItemLayout}
          >
            {state.masterServices.data !== undefined ? (
              <Row>
                <Col span={18}>
                  <div
                    style={{
                      // padding: 1,
                      // height: 0,
                      // background: "#eee",
                      position: "relative",
                    }}
                    id="area1"
                  >
                    <Form.Item
                      name="masterService"
                      label="Select Bouquet Name"
                      rules={[
                        {
                          required: true,
                          message: "Please select your Bouquet ",
                          // type: "array",
                        },
                      ]}
                    >
                      <Select
                        // mode="single"
                        placeholder="Please select  Bouquet"
                        getPopupContainer={() =>
                          document.getElementById("area1")
                        }
                        onChange={handleChangeMainService}
                      >
                        {state.masterServices.masterServicesName.map(
                          (item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ) : null}

            {state.services.length > 0 &&
            state.masterServices.data !== undefined ? (
              <Row>
                <Col span={18}>
                  <div
                    style={{
                      // padding: 1,
                      // height: 0,
                      // background: "#eee",
                      position: "relative",
                    }}
                    id="area1"
                  >
                    <Form.Item
                      name="mainService"
                      label="Select  Channel"
                      rules={[
                        {
                          required: true,
                          message: "Please select your Channel",
                          // type: "array",
                        },
                      ]}
                    >
                      <Select
                        // mode="single"
                        placeholder="Please select  Service"
                        getPopupContainer={() =>
                          document.getElementById("area1")
                        }
                      >
                        {state.services.map((item, index) =>
                          state.masterServices.data
                            .filter(
                              (data) =>
                                data.masterServicename === selectedMainService
                            )
                            .map((itemService) =>
                              itemService.catids === item.category_id ? (
                                <Option key={index} value={item.serviceName}>
                                  {item.serviceName}
                                </Option>
                              ) : null
                            )
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ) : null}
            <div
              style={{
                // width: "200px",
                // padding: 1,
                // height: 0,
                // background: "#eee",
                position: "relative",
              }}
              id="area"
            >
              <Row>
                <Col span={18}>
                  <Form.Item
                    // style={{
                    //   width: "300px",
                    // }}
                    name="selectmultiple"
                    label="Choose Series"
                    rules={[
                      {
                        required: true,
                        message: "Please select your Sub Category",
                        type: "array",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Mutlitple/single"
                      getPopupContainer={() => document.getElementById("area")}
                    >
                      {state.category.map((item, index) => (
                        <Option key={index} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {/* <Form.Item
              name="image"
              label="Upload Logo of Service"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Upload
                name={"image"}
                accept=".png, .jpg, .jpeg"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                // maxCount={1}
                customRequest={dummyRequest}
              >
                {fileList.length < 1 && "+ Upload"}
              </Upload>
              {/* </ImgCrop> */}
            {/* </Form.Item> */}
            <Form.Item
              wrapperCol={{
                span: 12,
                offset: 6,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {loader && <Spinner />}
    </ServicesContainer>
  );
};

export default Services;

const ServicesContainer = styled.div`
  height: 100%;
  .add-product {
    min-height: 50px;
    min-width: 100%;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        color: cyan;
      }
      h5 {
        color: white;
        padding: 0 10px;
        :hover {
          color: cyan;
        }
      }
    }
  }
  .add-container {
    border: 1px solid black;
    width: 100%;
    min-height: 100%;
    padding: 20px;
  }
`;
