import React, { useState, useEffect, useContext } from "react";
import { Table, Input, Typography, Form, Switch, Spin } from "antd";
import styled from "styled-components";
import axios from "axios";
import userContext from "../../context/user/userContext";
import { arrayMoveImmutable } from "array-move";

import { MenuOutlined } from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import "./CategoryTable.css";
// import axios from "axios";

const CategoryTable = ({
  items,
  deleteitem,
  editItem,
  toggleCategory,
  fullItem,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState(items);
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  const { updateSortedCategory } = useContext(userContext);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
      }}
    />
  ));

  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  //   title: "",
  //   dataIndex: "",

  //   key: "",

  const onDelete = (key, e) => {
    e.preventDefault();
    console.log(key);
    deleteitem(key.id);

    // const data = this.state.data.filter(item => item.key !== key);
    // this.setState({ data, isPageTween: false });
  };

  const onChanged = async (checked, key) => {
    console.log(checked, key);

    try {
      const response = axios.patch("/category/enable-disable-catItems", {
        catId: key.parent_id,
        sub_cat_id: key.sub_cat_id,
        status: checked,
      });
    } catch (exception) {
      alert("Some thing went wrong");
    }
    // console.log("checked", key);
    // setDisabled(checked);
    // toggleItem(checked, key);
    toggleCategory({ ...key, status: checked });
  };

  useEffect(() => {
    const tempColumn = [];
    console.log("items ", items);
    console.log("tempColumn.length ", tempColumn.length);
    if (items.length > 0 && tempColumn.length <= 0)
      items.forEach((item) => tempColumn.push(Object.keys(item)));

    if (items.length > 0 && columns.length <= 0) {
      setColumns([
        {
          title: "Sort",
          dataIndex: "sort",
          width: 30,
          className: "drag-visible",
          render: () => <DragHandle />,
        },
      ]);
      // for column
      tempColumn[0].map((item) => {
        // console.log(item);
        setColumns((prevState) => [
          ...prevState,

          {
            title: item,
            dataIndex: item,
            key: item,
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
        ]);
      });
    }

    if (items.length > 0 && columns.length <= 0)
      setColumns((prevState) => [
        ...prevState.filter(
          (item) =>
            item.title != "parent_id" &&
            item.title != "status" &&
            item.title != "id" &&
            item.title != "partnerName"
        ),

        {
          title: "Action",
          dataIndex: "",
          key: "id",
          render: (text, record) => (
            <a
              style={{
                backgroundColor: "red",
                padding: "10px",
                borderRadius: "5px",
                color: "white",
              }}
              onClick={(e) => {
                onDelete(record, e);
              }}
            >
              Delete
            </a>
          ),
        },
        {
          title: "operation",
          dataIndex: "operation",
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <Typography.Link
                  style={{
                    marginRight: 8,
                  }}
                >
                  Save
                </Typography.Link>
              </span>
            ) : (
              <span
                onClick={() => console.log(record.key)}
                style={{
                  padding: "12px",
                  backgroundColor: "blue",
                  borderRadius: "5px",
                }}
              >
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                  style={{ color: "white" }}
                >
                  Edit
                </Typography.Link>
              </span>
            );
          },
        },
      ]);

    if (items.length > 0 && columns.length <= 0)
      setColumns((prevState) => [
        ...prevState.filter((item) => item.title !== "sub_cat_id"),
        {
          align: "center",
          width: 200,
          title: "Disable/Enable",
          key: "operation",
          defaultSortOrder: "descend",
          dataIndex: "status",
          // sorter: (a, b) => a.status - b.status,
          // fixed: "right",

          render: (_, record) =>
            items.length > 0 ? (
              <a
              // style={{
              //   backgroundColor: "red",
              //   padding: "10px",
              //   borderRadius: "5px",
              //   color: "white",
              // }}
              >
                <Switch
                  size="small"
                  defaultChecked={record.status}
                  onChange={(checked, e) => onChanged(checked, record, e)}
                  // onChange={onChanged}
                />
              </a>
            ) : (
              <h1>Hi</h1>
            ),
        },
      ]);

    // for data source
    if (items.length >= 0) {
      setDataSource([]);
      items.map((item) => {
        // console.log(item);
        setDataSource((prevState) => [...prevState, item]);
      });
    }

    //   if(items.length>0)
  }, [items]);

  //   console.log(" items in table Cat ", columns);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    // console.log("RECORD ", record);
    editItem(record);
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // console.log("dataSource ", dataSource);
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.sequence === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const search = (value) => {
    console.log("value ", value);
    // let value = evt.target.value;
    // const { baseData } = this.state;
    // console.log("PASS", evt.target.value);

    const filterTable = items.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    console.log("filterTable ", filterTable);

    setDataSource(filterTable);
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    try {
      setLoader(true);
      const formData = new FormData();

      // sort-data/series
      console.log(oldIndex);
      console.log(newIndex);
      if (oldIndex < newIndex) console.log("Moved downwards");
      else console.log("Moved Upwards");

      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable(
          dataSource.slice(),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        // console.log(" old data items: ", newData);
        for (let i = 0; i < newData.length; i++) {
          newData[i].sequence = i;
        }
        console.log("Sorted items: ", newData);
        newData.forEach((item) => {
          formData.append("series", JSON.stringify(item));
        });

        const response = await axios.post("/sort-data/series", formData, {
          // xfheaders: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        });

        console.log("RESPONSE ", response.data.message);
        updateSortedCategory(newData);
        setLoader(false);
        setDataSource(newData);
      }
    } catch (e) {
      setLoader(false);
      alert("Some thing went wrong");
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  console.log(items);
  console.log("dataSource ", dataSource);
  return (
    <TableStyled>
      {/* <Input.Search
        style={{ margin: "0 0 10px 0", width: "100%" }}
        placeholder="Search by..."
        enterButton
        // onChange={search}
        onSearch={search}
        allowClear
      /> */}
      <Form form={form} component={false}>
        <Table
          dataSource={dataSource}
          loading={loader}
          columns={columns}
          rowKey="sequence"
          pagination={{ pageSize: 30 }}
          bordered
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Form>
      ;
    </TableStyled>
  );
};

export default CategoryTable;

const TableStyled = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 20px;

  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
`;
