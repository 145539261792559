import React from "react";
import { Modal, Button, Spin } from "antd";
import styled from "styled-components";
import { BiMessageSquareError } from "react-icons/bi";
import { FcCheckmark } from "react-icons/fc";

const ModalDialog = ({
  error,
  successMessage,
  visible,
  onCancel,
  onOk,
  heading,
  children,
  showSubmit = true,
  width = 500,
  // formSubmitHandler,
  isLoading = false,
}) => {
  // console.log("MODAL SUCCESS ", successMessage);

  if (showSubmit)
    return (
      <Modal
        width={width}
        title={heading}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={[
          <p key="error" style={{ textAlign: "center", color: "red" }}>
            {error ? (
              <BiMessageSquareError size={15} style={{ color: "red" }} />
            ) : null}
            {error ? `${error} ` : null}
          </p>,

          <p key="success" style={{ textAlign: "center", color: "green" }}>
            {successMessage ? (
              <FcCheckmark size={15} style={{ color: "green" }} />
            ) : null}
            {successMessage ? `${successMessage} ` : null}
          </p>,

          !isLoading ? (
            <Button key="back" onClick={onCancel}>
              Cancel
            </Button>
          ) : null,

          <Button
            key="submit"
            type="primary"
            onClick={onOk}
            style={{ minWidth: "100px" }}
          >
            {isLoading ? <Spin /> : "Submit"}
          </Button>,
        ]}

        //   width={"auto"}
      >
        {children}
      </Modal>
    );
  else
    return (
      <Modal
        title={heading}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        //   width={"auto"}
      >
        {children}
      </Modal>
    );
};

export default ModalDialog;

const ModalStyled = styled.div`
  .ant-modal-content {
    border-radius: 20px !important;
    /* background: red; */
  }
`;
