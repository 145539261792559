import React, { useContext } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { BiVideo } from "react-icons/bi";
import "react-pro-sidebar/dist/css/styles.css";
import { FaGem } from "react-icons/fa";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  HiLogout,
  HiOutlineChartSquareBar,
  HiDesktopComputer,
  HiSortDescending,
} from "react-icons/hi";
import "./SideBar.css";

import userContext from "../../context/user/userContext";

const SideBar = () => {
  const { state, removeToken } = useContext(userContext);

  const navigate = useNavigate();

  const { sideBar } = state;

  console.log("sideBarOpen ", sideBar);
  return (
    <>
      <SideBarStyled>
        <ProSidebar
          // open={true}

          collapsed={false}
        >
          <Menu iconShape="square" popperArrow={true}>
            {!sideBar ? (
              <MenuItem
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  margin: "10px",
                }}
              >
                Welcome
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem
              icon={<HiSortDescending />}
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              icon={<HiSortDescending />}
              onClick={() => navigate("/service-categories")}
            >
              Channels
            </MenuItem>
            <MenuItem
              icon={<HiDesktopComputer />}
              onClick={() => navigate("/categories")}
            >
              Assign Series to Channels
            </MenuItem>

            <MenuItem
              icon={<HiOutlineChartSquareBar />}
              onClick={() => navigate("/sub-categories")}
            >
              Create/Re-Order Series
            </MenuItem>
            <MenuItem
              icon={<HiDesktopComputer />}
              onClick={() => navigate("/products")}
            >
              All Episodes
            </MenuItem>

            <MenuItem icon={<BiVideo />} onClick={() => navigate("/teasers")}>
              Video Teasers
            </MenuItem>

            <MenuItem
              icon={<HiLogout />}
              onClick={() => {
                removeToken();
                navigate("/login");
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </ProSidebar>
      </SideBarStyled>
    </>
  );
};

export default React.memo(SideBar);

const SideBarStyled = styled.div`
  position: fixed;
  /* top: 0vh; */
  /* bottom: 0; */
  /* height: 100%; */
  min-height: 100vh;
  background-color: yellow;
  min-width: 270px;
`;
