import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { Tooltip, Input, Form, Upload, Select, Button } from "antd";
import { useParams } from "react-router-dom";

import userContext from "../../context/user/userContext";
import Spinner from "../../components/Spinner/Spinner";
const EditServices = () => {
  const { Option } = Select;
  const { slug } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state, sideBarOpen } = useContext(userContext);
  const [fileList, setFileList] = useState([]);
  const { sideBar, services, category } = state;
  const [categoryItems, setCategoryItems] = useState([]);
  const [servicesItems, setServicesItems] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (services.length > 0 && category.length > 0) {
      const item = services.filter((item) => item.id == slug)[0];
      console.log("ITEM ", item);
      setServicesItems(item);
      const catItems = category.filter((data) => data.parent_id == item.id);
      console.log("catItems ", catItems);
      setCategoryItems(catItems);
      setFileList([
        {
          uid: "-1",
          name: item.serviceName,
          status: "done",
          url: item.logoUrl,
        },
      ]);
      setLoader(false);
    }
  }, [services, category]);

  const handleSubmit = async (values) => {
    let subItems = values.selectmultiple.map((item) =>
      state.category.find((data) => data.name === item)
    );
    console.log(values);
    console.log(subItems);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("partnerName", state.partnerName);
    formData.append("image", values.image.file.originFileObj);
    formData.append("ExistingServiceNameId", slug);

    subItems.forEach((item) => {
      console.log("item ", item);
      formData.append("categories", JSON.stringify(item));
    });
    // setLoader(true);
    try {
      const response = await axios.post("/services/edit-services", formData, {
        headers: { Authorization: `Bearer ${state.jsonToken}` },
        "content-type": "multipart/form-data",
      });
      //   setLoader(false);
      //   updateServicesItems({});
      //   form.resetFields();
      setFileList([]);
      navigate("/services");
    } catch (err) {
      //   setLoader(false);
      alert("Some thing went wrong ", err);
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    console.log(fileList);
    setFileList(newFileList);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  console.log(categoryItems);
  console.log("loader ", servicesItems);
  if (loader)
    return (
      <div style={{ minHeight: "100vh" }}>
        <Spinner />
      </div>
    );

  return (
    <ServicesContainer sideBar={sideBar}>
      <div className="add-product">
        <div className="menu-icon">
          <AiOutlineMenu
            onClick={() => {
              sideBarOpen();
            }}
          />
        </div>
      </div>
      <div className="add-container">
        <h3 style={{ textAlign: "center", padding: "20px" }}>
          Edit Service
          <span style={{ fontWeight: 800 }}>{servicesItems.serviceName}</span>
        </h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
            // {...formItemLayout}
          >
            <Form.Item
              name="title"
              label="Enter Service Name"
              rules={[{ required: true, message: "This field is required." }]}
              style={{ width: 500 }}
              initialValue={servicesItems.serviceName}
            >
              <Input placeholder={servicesItems.serviceName} />
            </Form.Item>
            <Form.Item
              name="selectmultiple"
              label="Select Categories"
              initialValue={categoryItems.map((item) => item.name)}
              rules={[
                {
                  required: true,
                  message: "Please select categories",
                  type: "array",
                },
              ]}
            >
              <Select
                allowClear
                mode="multiple"
                placeholder="Please select Categories"
                // defaultValue={categoryItems.map((item) => item.name)}
              >
                {state.category.map((item) => (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="image"
              label="Upload Logo of Service"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Upload
                name={"image"}
                accept=".png, .jpg, .jpeg"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                maxCount={1}
                customRequest={dummyRequest}
              >
                {fileList.length < 2 && "+ Upload"}
              </Upload>
              {/* </ImgCrop> */}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 12,
                offset: 6,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </ServicesContainer>
  );
};

export default EditServices;

const ServicesContainer = styled.div`
  min-height: 100vh;
  .add-product {
    min-height: 50px;
    min-width: 100vw;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
  }
`;
