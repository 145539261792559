import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Input, Form, Upload, Select, Button } from "antd";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

import userContext from "../../context/user/userContext";
import Spinner from "../../components/Spinner/Spinner";

const AddVideos = () => {
  let country = "";
  let menu = "";
  const { Option } = Select;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploadType, setUploadType] = useState("upload");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedMainService, setSelectedMainService] = useState("");
  const [serviceChanged, setServiceChanged] = useState([]);
  const [subServiceChanges, setSubServiceChanged] = useState([]);
  const [mainServiceItem, setMainServiceItem] = useState([]);
  const [subCatIds, setSubCatIds] = useState([]);

  const [successAdded, setSuccessAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    state,
    updateLoader,
    removeToken,
    addOperator,
    addProductsItems,
    deleteProduct,
    updateProductItems,
    sideBarOpen,
    updateServicesItems,
  } = useContext(userContext);

  const { sideBar } = state;

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
    setSelectedOperator(value);
  }
  function handleChangeUpload(value) {
    console.log(`selected ${value}`);
    setUploadType(value);
  }
  const onChange = ({ fileList: newFileList }) => {
    console.log(fileList);
    setFileList(newFileList);
  };

  function handlesubServiceChange(value) {
    console.log("handlesubServiceChange ", value);
    setSubServiceChanged(value);
  }

  function handleChangeServiceChange(value) {
    console.log(console.log(`selected ${value}`));
    setServiceChanged(value);
  }

  function handleChangeMainService(value) {
    console.log(`selected ${value}`);
    setSelectedMainService(value);
  }

  const handleSubmitUrl = async (values) => {
    const {
      title,
      operator,
      country,
      category,
      description,
      image,
      video,
      duration,
    } = values;

    try {
      updateLoader(true);
      const catId = state.category.find((item) => {
        console.log(item);
        console.log(category);
        setIsLoading(true);
        if (item.name === category) return item.id;
      });
      console.log("CAT ID ", catId);
      // console.log("values.image.originFileObj ", values.video[0]);

      const formData = new FormData();
      formData.append("partnerName", state.partnerName);
      formData.append("image", image);
      formData.append("video", video);
      formData.append("title", title);
      formData.append("operator", operator);
      formData.append("country", country);
      formData.append("description", description);
      formData.append("catId", catId.id);
      formData.append("duration", duration);
      formData.append("status", 0);
      // formData.append("status", service);

      // formData.append("age", 20);

      // console.log("formData ", formData);

      console.log(values);

      const response = await axios.post(
        "/addProducturl/addItem",
        {
          image,
          partnerName: state.partnerName,
          video,
          title,
          operator,
          country,
          description,
          catId: catId.id,
          duration,
          status: 0,
        },

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );

      const responseFetch = await axios.get(
        `/product/fetch-products/?partner=${state.partnerName}`,

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      console.log("fetch-products ", responseFetch.data.message);
      updateLoader(false);
      addProductsItems(responseFetch.data.message);
      setIsLoading(false);
      setSuccessAdded(true);
      alert("product Uploaded Successfully");

      console.log("RESPONSE ", response);
    } catch (err) {
      updateLoader(false);
      setIsLoading(false);
      navigate("/login");
      alert("Some thing went wrong pleaase try again later");
    }
  };

  const handleSubmit = async (values) => {
    const {
      title,
      operator,
      country,
      category,
      description,
      image,
      video,
      // duration,
    } = values;

    try {
      setIsLoading(true);
      updateLoader(true);
      console.log("CAT ID ", serviceChanged);
      console.log("Sub ID ", subServiceChanges);
      console.log("values.image.originFileObj ", values.video[0]);

      const formData = new FormData();
      formData.append("partnerName", state.partnerName);
      formData.append("image", values.image.file.originFileObj);
      formData.append("video", values.video[0].originFileObj);
      formData.append("title", title);
      formData.append("operator", operator);
      formData.append("country", country);
      formData.append("description", description);
      // formData.append("catId", catId.id);
      formData.append("catId", serviceChanged);
      formData.append("sub_category_id", subServiceChanges);
      // formData.append("duration", duration);
      formData.append("status", 0);

      console.log("values of input data  for product is ", values);

      const response = await axios.post(
        "/product/add-product",
        formData,

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      updateLoader(false);

      form.resetFields();
      setFileList([]);
      alert("Video Inserted Successfully ");
      const responseFetch = await axios.get(
        `/product/fetch-products/?partner=${state.partnerName}`,

        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
          "content-type": "multipart/form-data",
        }
      );
      console.log("fetch-products ", responseFetch.data.message);
      addProductsItems(responseFetch.data.message);
      setIsLoading(false);
      setSuccessAdded(true);

      console.log("RESPONSE ", response);
    } catch (err) {
      console.log(err.response);
      setIsLoading(false);
      updateLoader(false);
      alert("Some thing went wrong pleaase try again later");
    }
  };

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    // console.log(" ADD opeartor");
    const fetchOperator = async (jsonToken) => {
      // updateLoader(true);
      const response = await axios.get("/operator/fetch-operator", {
        headers: { Authorization: `Bearer ${state.jsonToken}` },
      });

      return await response.data.data;
    };

    if (state.jsonToken) {
      fetchOperator(state.jsonToken)
        .then((item) => {
          // console.log("jo");
          addOperator(item);
          updateLoader(false);
        })
        .catch((err) => {
          console.log(err);
          // navigate("/login");
          updateLoader(false);
          removeToken();
        });
    }
  }, [state.jsonToken]);

  return (
    <AddVideoStyled>
      <div className="container">
        <h3>Upload Videos </h3>
        <Form
          form={form}
          onFinish={uploadType === "upload" ? handleSubmit : handleSubmitUrl}
          autoComplete="off"
          // {...formItemLayout}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Enter Title"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Col span={10}>
                <Form.Item
                  name="operator"
                  label="Enter Operator"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select placeholder="select operator" onChange={handleChange}>
                    {state.operator.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="country"
                  label="Choose Country"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    placeholder="select Country"
                    // onChange={handleChange}
                  >
                    {state.operator.map((item, index) => {
                      // console.log(" ITEM ", item, selectedOperator);
                      if (item.name === selectedOperator)
                        return (
                          <Option key={index} value={item.name}>
                            {item.country}
                          </Option>
                        );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </div>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Enter Description"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input.TextArea
                  placeholder="Enter Description"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Col span={10}>
                <Form.Item
                  name="mainService"
                  label="Enter Bouquite Name"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    placeholder="Select Bouquite"
                    onChange={handleChangeMainService}
                  >
                    {state.masterServices.masterServicesName !== undefined &&
                      state.masterServices.masterServicesName.map(
                        (item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        )
                      )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item
                  name="category"
                  label="Enter Channel"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    placeholder="select Category"
                    onChange={handleChangeServiceChange}
                  >
                    {/* handleChangeServiceChange */}
                    {state.masterServices.masterServicesName !== undefined &&
                      state.services.map((catItem) =>
                        state.masterServices.data
                          .filter(
                            (master) =>
                              master.masterServicename === selectedMainService
                          )
                          .map((data, index) =>
                            data.catids === catItem.category_id ? (
                              <Option key={index} value={catItem.category_id}>
                                {catItem.serviceName}
                              </Option>
                            ) : null
                          )
                      )}
                  </Select>
                </Form.Item>
              </Col>
            </div>
          </Row>

          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Col span={12}>
                <Form.Item
                  name="subcategory"
                  label="Choose Series"
                  rules={[
                    { required: true, message: "This field is required." },
                  ]}
                >
                  <Select
                    placeholder="select Series"
                    onChange={handlesubServiceChange}
                  >
                    {state.category.map((item) =>
                      state.services
                        .filter(
                          (service) => service.category_id === serviceChanged
                        )
                        .map((data, inx) =>
                          data.category_id === item.parent_id ? (
                            <Option key={data.name} value={item.sub_cat_id}>
                              {item.name}
                            </Option>
                          ) : null
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </div>
          </Row>

          {uploadType == "upload" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="video"
                    label="Upload Video"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}

                    // extra="longgggggggggggggggggggggggggggggggggg"
                  >
                    <Upload
                      accept="video/*"
                      name={"video"}
                      listType="picture"
                      customRequest={dummyRequest}
                      // style={{ display: "block !important" }}
                      // onChange={chosenVideoHandler}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ) : uploadType == "url" ? (
            <Form.Item
              name="video"
              label="Enter Video Url"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input placeholder="Enter url" type="text" />
            </Form.Item>
          ) : null}

          {uploadType === "upload" ? (
            <Form.Item name="image" label="Upload Image">
              <Upload
                name={"image"}
                accept=".png, .jpg, .jpeg"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                // maxCount={1}
                customRequest={dummyRequest}
              >
                {fileList.length < 1 && "+ Upload"}
              </Upload>
              {/* </ImgCrop> */}
            </Form.Item>
          ) : uploadType == "url" ? (
            <Form.Item
              name="image"
              label="Enter Image Url"
              rules={[{ required: true, message: "This field is required." }]}
            >
              <Input placeholder="Enter url" type="text" />
            </Form.Item>
          ) : null}

          <Form.Item
            wrapperCol={{
              span: 18,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      {isLoading ? <Spinner /> : null}
    </AddVideoStyled>
  );
};

export default AddVideos;

const AddVideoStyled = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  justify-content: center;
  .container {
    height: 100%;
    width: 80%;
    padding: 20px;
    h3 {
      text-align: center;
      font-size: 30px;
      text-shadow: 2px 2px #ff0000;
    }
    .ant-upload-list {
      display: block;
    }
  }
`;
