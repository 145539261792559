import React, { useContext } from "react";
import { useCookies } from "react-cookie";

import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Footer from "../Footer/Footer";
import SideBar from "../Sidebar/SideBar";
import userContext from "../../context/user/userContext";

// const navbarHeight = "71.55px";
const footerHeight = "63.59px";

const Layout = (props) => {
  const { state } = useContext(userContext);
  const { jsonToken } = state;
  let location = useLocation();

  const [cookies, setCookie] = useCookies(["user"]);

  //   console.log(jsonToken);
  return (
    <div style={{ display: "flex", boxSizing: "border-box" }}>
      {jsonToken !== "" ? (
        <div style={{ width: "200px", height: "100vh" }}>
          <SideBar />
        </div>
      ) : null}

      <div
        style={{
          //   width: `calc(100vw-270px)`,
          width: `${(jsonToken) =>
            jsonToken !== "" ? "100vw" : `calc(100vw-270px)`}`,
          height: `calc(100vh  - ${footerHeight})`,
          position: "absolute",
          left: `${jsonToken !== "" ? `270px` : "0"}`,
          right: 0,
          //   right: `${jsonToken !== "" ? `0   ` : "0"}`,
        }}
      >
        {props.children}

        <Footer />
      </div>
    </div>
  );
};

export default Layout;
