import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { IoIosAddCircle } from "react-icons/io";

import userContext from "../../context/user/userContext";
import ServicesTable from "../../components/ServicesTable/ServicesTables";
import Spinner from "../../components/Spinner/Spinner";
const Services = () => {
  const navigate = useNavigate();
  const {
    state,

    updateLoader,
    // removeToken,
    // addOperator,
    // addProductsItems,aaj dikh
    // deleteProduct,
    // updateProductItems,
    sideBarOpen,
    deleteServices,
  } = useContext(userContext);

  const onDelete = async (id) => {
    try {
      updateLoader(true);
      console.log(" DELETE ", id);

      const response = await axios.delete(
        "/services/removeServices",
        {
          data: {
            id,
            partnerName: state.partnerName,
          },
        },
        {
          headers: { Authorization: `Bearer ${state.jsonToken}` },
        }
      );
      updateLoader(false);
      deleteServices(id);
      console.log("response ", response.data.message);

      // return await response.data.message;
    } catch (err) {
      updateLoader(false);
      alert("SOME THING Went wrong try again later");
    }
  };

  const { sideBar } = state;
  // console.log("SIDEBAR", sideBar);

  useEffect(() => {
    if (!sideBar) sideBarOpen();
  }, []);
  return (
    <ServicesContainer sideBar={sideBar}>
      <div className="add-product">
        {/* <span onClick={() => setIsModalVisible(true)}> */}
        <span onClick={() => navigate("/add-service")}>
          <h5>Assign Series to Channel</h5>
          <Tooltip title="Add Service">
            {<IoIosAddCircle size={"20px"} className="add-icon" />}
          </Tooltip>
        </span>
      </div>
      <ServicesTable
        items={state.services}
        deleteitem={onDelete}
        editItem={(data) => navigate(`/edit-service/${data.id}`)}
      />
      {state.loader && <Spinner />}
    </ServicesContainer>
  );
};

export default Services;

const ServicesContainer = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  .add-product {
    min-height: 50px;
    min-width: 100%;
    width: 100%;
    background-color: var(--ndoto-header-bg);
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    .menu-icon {
      color: white;
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      left: ${(props) => (!props.sideBar ? "300px" : "100px")};
      z-index: 102;
    }

    .add-icon {
      cursor: pointer;
      :hover {
        color: cyan;
      }
    }
    .home-icon {
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        color: cyan;
      }
      h5 {
        color: white;
        padding: 0 10px;
        :hover {
          color: cyan;
        }
      }
    }
  }
`;
