import React, { useReducer } from "react";
import { useCookies } from "react-cookie";

import UserContext from "./userContext";

const initState = {
  userName: "",
  jsonToken: "",
  category: [],
  operator: [],
  teasers: [],
  loader: false,
  products: [],
  partnerName: "",
  sideBar: true,
  services: [],
  masterServices: "",
};
const reducer = (state, action) => {
  // console.log("STATEEEEE ", state);
  // console.log("ACTION TYPE ", action.type);
  switch (action.type) {
    case "UPDATE_TOKEN":
      // console.log(action.payload);
      return {
        ...state,
        jsonToken: action.payload.jsonToken,
        userName: action.payload.username,
        partnerName: action.payload.partnerName,
      };
    case "ADD_CATEGORY":
      return {
        ...state,
        category: action.payload,
      };

    case "ADD_MASTER_SERVICES":
      return {
        ...state,
        masterServices: action.payload,
      };
    case "ADD_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };
    case "ADD_TEASERS":
      return {
        ...state,
        teasers: action.payload,
      };

    case "ADD_OPERATOR":
      return {
        ...state,
        operator: action.payload,
      };

    case "UPDATE_LOADER":
      return {
        ...state,
        loader: action.payload,
      };

    case "ADD_SERVICES":
      return {
        ...state,
        services: action.payload,
      };
    case "UPDATE_USER":
      const { username, jsonToken } = action.payload;
      // console.log("USERNAME IS ", state);
      // console.log("ACTION PAYLOD ", action.payload);
      return {
        ...state,
        userName: action.payload.username,
        jsonToken: action.payload.jsonToken,
        partnerName: action.payload.partnerName,
      };

    case "DELETE_TEASER":
      // console.log(" ACTION PAYLOD ", action.payload);
      return {
        ...state,
        teasers: state.teasers.filter((item) => item.id !== action.payload),
      };
    case "DELETE_PRODUCT":
      // console.log(" ACTION PAYLOD ", action.payload);
      return {
        ...state,
        products: state.products.filter((item) => item.id !== action.payload),
      };
    case "DELETE_SERVICES":
      // console.log(" ACTION PAYLOD ", action.payload);
      return {
        ...state,
        services: state.services.filter((item) => item.id !== action.payload),
      };
    case "UPDATE_CATEGORY":
      return {
        ...state,
        category: [...state.category, action.payload],
      };

    case "UPDATE_NEW_CATEGORY":
      console.log("ACTION PAYLOAD", action.payload);
      // filter out the element
      const copyOfArray = state.category.filter(
        (l) => l.id !== action.payload.id
      );
      // put to the beginning of the array
      copyOfArray.unshift(action.payload);
      return {
        ...state,
        category: copyOfArray,
      };

      console.log("copyOfArray ", copyOfArray);

    case "  ":
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    case "DELETE_CATEGORY":
      console.log("ACTION PAYLOAD ", action.payload);
      return {
        ...state,
        category: state.category.filter((item) => item.id !== action.payload),
      };

    case "UPDATE_SORTED_CAT":
      console.log("ACTION PAYLOAD ", action.payload);
      let filterItem = state.category.filter((catItem) =>
        action.payload.some((value) => catItem.id === value.id)
      );
      let otherItem = state.category.filter((catItem) =>
        action.payload.some((value) => catItem.id !== value.id)
      );

      return {
        ...state,
        category: filterItem.concat(otherItem),
      };

    case "EDIT_CATEGORY":
      console.log(" action payload ", action.payload);
      return {
        ...state,
        category: state.category.map((item) =>
          item.id === action.payload.id
            ? { ...item, name: action.payload.categeoryName }
            : item
        ),
      };

    case "EDIT_PRPDUCTIMAGE":
      console.log(" action payload ", action.payload);
      return {
        ...state,
        products: state.products.map((item) =>
          item.id === action.payload.id
            ? { ...item, imgurl: action.payload.imageUrl }
            : item
        ),
      };
    case "FLUSH_STATE":
      return {
        ...initState,
      };
    case "UPDATE_SERVICES":
      console.log(action.payload);
      return {
        ...state,
        products: state.products.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };

    case "UPDATE_TEASERS":
      console.log(action.payload);
      return {
        ...state,
        teasers: state.teasers.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };

    case "UPDATE_SERVICE":
      console.log(action.payload);
      return {
        ...state,
        services: [...state.services, action.payload],
      };

    case "SIDEBAROPEN":
      return {
        ...state,
        sideBar: !state.sideBar,
      };

    case "TOGGLECATITEMS":
      console.log("PAYLOAD TOGGLE", action.payload);
      return {
        ...state,
        products: state.products.map((item) =>
          item.category_id === action.payload.parent_id &&
          item.sub_category_id === action.payload.sub_cat_id
            ? { ...item, status: action.payload.status }
            : item
        ),
        category: state.category.map((item) =>
          item.parent_id === action.payload.parent_id &&
          item.sub_cat_id === action.payload.sub_cat_id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };
    default:
      return state;
  }
};

const UserState = (props) => {
  // const [state, setState] = useState(initState);
  const [state, dispatch] = useReducer(reducer, initState);
  // console.log(state);
  const updateUser = (username, jsonToken, partnerName) => {
    // console.log("username, jsonToken ", username, jsonToken);
    dispatch({
      type: "UPDATE_USER",
      payload: { username, jsonToken, partnerName },
    });
  };

  const updateToken = (username, jsonToken, partnerName) => {
    // console.log("username, jsonToken ", jsonToken);

    if (username !== "")
      dispatch({
        type: "UPDATE_TOKEN",
        payload: { username, jsonToken, partnerName },
      });
  };

  const removeToken = () => {
    window.localStorage.removeItem("token");
    removeCookie("user");

    dispatch({
      type: "FLUSH_STATE",
    });

    // setState(initState);
    // _.set(initState, "jsonToken", "");
  };

  const sideBarOpen = () => {
    dispatch({
      type: "SIDEBAROPEN",
    });

    // setState(initState);
    // _.set(initState, "jsonToken", "");
  };

  const toggleItem = (item) => {
    dispatch({
      type: "TOGGLECATITEMS",
      payload: item,
    });
  };
  const addCategoryItems = (categoryItem) => {
    // console.log("categoryItem ", categoryItem);

    dispatch({ type: "ADD_CATEGORY", payload: categoryItem });

    // _.set(initState, "category", categoryItem);
  };

  const addMasterServices = (masterItem) => {
    console.log("categoryItem ", masterItem);

    dispatch({ type: "ADD_MASTER_SERVICES", payload: masterItem });

    // _.set(initState, "category", categoryItem);
  };

  const addProductsItems = (items) => {
    dispatch({ type: "ADD_PRODUCTS", payload: items });
  };

  const updateServicesItems = (items) => {
    console.log("ITENS ", items);
    dispatch({ type: "UPDATE_SERVICES", payload: items });
  };

  const updateTeaserItems = (items) => {
    console.log("ITENS ", items);
    dispatch({ type: "UPDATE_TEASERS", payload: items });
  };

  const updateServices = (items) => {
    console.log("ITENS ", items);
    dispatch({ type: "UPDATE_SERVICE", payload: items });
  };

  const addServicesItems = (items) => {
    // console.log("addServicesItems ", items);
    dispatch({ type: "ADD_SERVICES", payload: items });
  };
  const addTeaserItems = (items) => {
    dispatch({ type: "ADD_TEASERS", payload: items });
  };

  const addOperator = (operatorItem) => {
    // console.log("operatorItem ", operatorItem);
    // console.log("STATE  BEFORE", state);

    dispatch({ type: "ADD_OPERATOR", payload: operatorItem });
    // _.set(initState, "operator", operatorItem);
  };

  const updateCategory = (categoryItem) => {
    dispatch({ type: "UPDATE_CATEGORY", payload: categoryItem });
    // console.log("CAT ITEM ", categoryItem);
    // setState({
    //   ...state,
    //   category: [...state.category, categoryItem],
    // });
  };

  const updateNewCategory = (categoryItem) => {
    console.log(" HERE NOW ", categoryItem);
    dispatch({ type: "UPDATE_NEW_CATEGORY", payload: categoryItem });
    // console.log("CAT ITEM ", categoryItem);
    // setState({
    //   ...state,
    //   category: [...state.category, categoryItem],
    // });
  };

  const deleteCategory = (id) => {
    // const { id } = id;
    console.log("ID ", id);

    dispatch({ type: "DELETE_CATEGORY", payload: id });

    // const data = this.state.data.filter((item) => item.key !== key);
    // console.log(
    //   "state.category.filter((item) => item.id != id) ",
    //   state.category.filter((item) => item.id != id)
    // );
    // setState({
    //   ...state,
    //   category: state.category.filter((item) => item.id != id),
    // });
    console.log("deleteCategory ", state.category);
  };

  const editCategory = (id, categeoryName) => {
    console.log("ID ", id, categeoryName);
    dispatch({ type: "EDIT_CATEGORY", payload: { id, categeoryName } });

    // setState({
    //   ...state,
    //   category: state.category.map((item) =>
    //     item.id === id ? { ...item, name: categeoryName } : item
    //   ),
    // });
  };
  const updateLoader = (loader) => {
    dispatch({ type: "UPDATE_LOADER", payload: loader });
    // UPDATE_LOADER;
  };
  const editProductImage = (id, imageUrl) => {
    dispatch({ type: "EDIT_PRPDUCTIMAGE", payload: { id, imageUrl } });
    // UPDATE_LOADER;
  };

  const updateSortedCategory = (catItem) => {
    dispatch({ type: "UPDATE_SORTED_CAT", payload: catItem });
  };
  const deleteProduct = (id) => {
    console.log("DELETE GING ", id);
    dispatch({ type: "DELETE_PRODUCT", payload: id });
  };

  const deleteServices = (id) => {
    console.log("DELETE deleteServices ", id);
    dispatch({ type: "DELETE_SERVICES", payload: id });
  };
  const deleteTeaser = (id) => {
    console.log("DELETE GING ", id);
    dispatch({ type: "DELETE_TEASER", payload: id });
  };
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  return (
    <UserContext.Provider
      value={{
        state,
        updateSortedCategory,
        updateTeaserItems,
        editProductImage,
        toggleItem,
        updateUser,
        updateToken,
        updateCategory,
        updateLoader,
        removeToken,
        deleteCategory,
        addCategoryItems,
        editCategory,
        addOperator,
        addProductsItems,
        deleteProduct,
        sideBarOpen,
        addTeaserItems,
        deleteTeaser,
        addServicesItems,
        deleteServices,
        updateServicesItems,
        addMasterServices,
        updateServices,
        updateNewCategory,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
